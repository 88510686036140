import React, { PropsWithChildren, useEffect, useReducer, useState } from 'react';
import { useSocket } from '@hooks/useSocket';
import { defaultSocketContextState, SocketContextProvider, SocketReducer } from './Context';

export interface ISocketContextComponentProps extends PropsWithChildren {}

const SocketContextComponent: React.FunctionComponent<ISocketContextComponentProps> = props => {
	const { children } = props;

	const devSocketURL = 'http://localhost:3001';
	const prodSocketURL = 'https://www.plutonn.com';

	// Socket Connection with http://localhost:3000 with 'websocket transport mechanism.

	/* 
		Transport Mechanism: ['websocket, polling],
		polling: use GET HTTP hanshake for two way communication.
		websocket: its  an optimized one on the basis of memory and time.
	*/

	const socket = useSocket(prodSocketURL, {
		transports: ['websocket', 'polling'],
		reconnection: true,
		withCredentials: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		timeout: 20000, //20 seconds
		reconnectionAttempts: Infinity,
		autoConnect: true,
	});

	const [SocketState, SocketDispatch] = useReducer(SocketReducer, defaultSocketContextState);

	const [loading, setLoading] = useState(true);

	const SendHandshake = () => {
		// console.info('Sending handshake to server ...');

		socket.emit('handshake', (uid: string, users: string[]) => {
			console.info('User handshake callback message received');
			SocketDispatch({ type: 'update_users', payload: users });
			SocketDispatch({ type: 'update_uid', payload: uid });
		});
		setLoading(false);
	};

	const StartListeners = () => {
		let reconnectionAttempts = 0;
		/** Messages */
		socket.on('user_connected', (users: string[]) => {
			console.info('User connected message received');
			SocketDispatch({ type: 'update_users', payload: users });
		});

		/** Messages */
		socket.on('user_disconnected', (uid: string) => {
			console.info('User disconnected message received');
			SocketDispatch({ type: 'remove_user', payload: uid });
		});

		/** Connection / reconnection listeners */
		socket.io.on('reconnect', attempt => {
			reconnectionAttempts = 0;
			console.info('Reconnected on attempt: ' + attempt);
			SendHandshake();
		});

		socket.io.on('reconnect_attempt', attempt => {
			reconnectionAttempts = attempt;
			console.info('Reconnection Attempt: ' + attempt);
		});

		socket.io.on('reconnect_error', error => {
			console.info('Reconnection error: ', error);
		});

		socket.io.on('reconnect_failed', () => {
			console.info('Reconnection failure.');
			if (reconnectionAttempts >= Infinity) {
				console.error('Max reconnection attempts reached.');
				// Optional: Implement further logic for when reconnection fails completely
			}
		});

		socket.io.on('reconnect', attempt => {
			const delay = Math.min(1000 * Math.pow(2, reconnectionAttempts), 30000); // Exponential backoff
			setTimeout(() => {
				console.info(`Reconnecting in ${delay / 1000} seconds...`);
				socket.connect();
			}, delay);
		});
	};

	useEffect(() => {
		socket.connect();
		SocketDispatch({ type: 'update_socket', payload: socket });
		StartListeners();
		SendHandshake();
		// eslint-disable-next-line

		// cleanup listner on unmount
		return () => {
			socket.off('user_connected');
			socket.off('user_disconnected');
			socket.io.off('reconnect');
			socket.io.off('reconnect_attempt');
			socket.io.off('reconnect_error');
			socket.io.off('reconnect_failed');
		};
	}, [socket]);

	// if (loading) return <p>... loading Socket IO ....</p>;

	return <SocketContextProvider value={{ SocketState, SocketDispatch }}>{children}</SocketContextProvider>;
};

export default SocketContextComponent;
