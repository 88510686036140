import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import pageLogoAnimation from '@data/plutonnLogoAnimation.json';
import styles from './style.module.scss';
import { RotatingLines, ThreeDots } from 'react-loader-spinner';

const PageLoader = () => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		lottie.loadAnimation({
			container: ref.current as HTMLDivElement,
			renderer: 'svg',
			animationData: pageLogoAnimation,
			loop: true,
			autoplay: true,
		});

		return lottie.destroy;
	}, []);

	return (
		<div className={styles.container}>
			<div ref={ref} className={styles.content} />
		</div>
	);
};

const CircleLoader = () => {
	return (
		<div className={styles.CircleCont}>
			<ThreeDots
				height='80'
				width='80'
				radius='9'
				color='var(--primary)'
				ariaLabel='three-dots-loading'
				wrapperStyle={{}}
				visible={true}
			/>
		</div>
	);
};

const ImageLoader = ({ color, width }: { color?: string; width?: string }) => {
	return (
		<div className={styles.ImageCont}>
			<RotatingLines
				strokeColor={color || 'var(--primary)'}
				strokeWidth='5'
				animationDuration='0.75'
				width={width || '36'}
				visible={true}
			/>
		</div>
	);
};

const Loader = {
	PageLoader,
	CircleLoader,
	ImageLoader,
};

export default Loader;
