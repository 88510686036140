import React from 'react';
import { useSelector } from 'react-redux';
//TYPES
import { CreatePostHeaderProps } from '$types/components/cards';
import { RootState } from '$types/store/store';
//COMPONENT
import Dropdown from '@components/dropdowns/TextDropdown';
import Image from '@components/partials/Avatar';
//DATA
import audienceData from '@data/post-audience.json';
//STYLE
import styles from './partials.module.scss';

function Header(props: CreatePostHeaderProps) {
	const { audience, handleChange } = props;

	const user = useSelector((state: RootState) => state.user.user);

	return (
		<header className={styles['post-header']}>
			<Image src={user?.profile || ''} alt={user?.name || 'profile'} loader='md' errStyle={{ fontSize: '2rem' }} />
			<Dropdown options={audienceData} selectedOption={audience} onSelectOption={handleChange} />
		</header>
	);
}

export default Header;
