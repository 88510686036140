import { Auth, User } from '$types/store/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		// token: sessionStorage.getItem('user') !== 'undefined' ? JSON.parse(sessionStorage.getItem('user')!) : undefined,
		user: undefined,
	} as Auth,
	reducers: {
		setUser: (state: Auth, action: PayloadAction<User | undefined>) => {
			state.user = action.payload as User;
			state.token = action.payload?.token ? action.payload.token : state.token;

			if (!action.payload) {
				// sessionStorage.removeItem('user');
				state.token = 'false';
				state.user = undefined;
			}

			// sessionStorage.setItem('user', JSON.stringify(state.token));
		},
		updateUser: (state: Auth, action: PayloadAction<User | undefined>) => {
			state.user = {...state.user, ...action.payload} as User;
		}
	},
});

export const { setUser, updateUser } = userSlice.actions;

export default userSlice.reducer;
