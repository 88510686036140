import { ButtonProps } from '$types/components';
import { Icon } from '@iconify/react';
import styles from './style.module.scss';

const renderIcon = (
	icon?: 'tick' | 'plus' | 'info' | 'cross' | 'edit' | string,
	type?: 'outline' | 'solid',
	onClick?: React.MouseEventHandler<SVGSVGElement>
) => {
	{
		switch (icon) {
			case 'plus':
				return (
					<Icon icon='material-symbols:add' color={type === 'outline' ? 'var(--primary)' : 'white'} width={25} height={25} />
				);
			case 'tick':
				return <Icon icon='mdi:tick-circle-outline' color={type === 'outline' ? 'black' : 'white'} width={20} height={20} />;
			case 'info':
				return <Icon icon='bi:info-circle' color={type === 'outline' ? 'black' : 'white'} width={18} height={18} />;
			case 'edit':
				return <Icon icon='ph:pencil-simple-fill' color={type === 'outline' ? 'black' : 'white'} width={24} height={24} />;
			case 'cross':
				return (
					<Icon
						icon='mdi:close-circle'
						color={type === 'outline' ? 'black' : 'white'}
						width={20}
						height={20}
						onClick={onClick}
					/>
				);
			default:
				return <Icon icon={icon ?? ''} color={type === 'outline' ? 'var(--primary)' : 'white'} width={25} height={25} />;
		}
	}
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
	const { text, children, onClick, disabled, icon, type, className, onClose } = props;

	return (
		<button
			className={`${styles.button} ${type === 'outline' ? styles.outlineStyle : ''} ${className}`}
			disabled={disabled}
			onClick={e => onClick && onClick(e)}>
			{renderIcon(icon, type, onClose)}
			{children ? children : text}
		</button>
	);
};

export default Button;
