import { lazy } from 'react';

export default {
	Notification: lazy(() => import('./notifications/notification')),
	Search: lazy(() => import('./search')),

	Post: lazy(() => import('./post-screen/post')),
	PostFeed: lazy(() => import('./posts-feed/feed')),
	AnnouncementFeed: lazy(() => import('./announcements-feed/feed')),

	CommunityAnnouncements: lazy(() => import('./community-announcements')),
	CommunityNotFound: lazy(() => import('./community-empty')),
	CommunityChats: lazy(() => import('./community-posts')),
	CommunityInfo: lazy(() => import('./community-info')),
	CommunityList: lazy(() => import('./community-list')),

	MessageInfo: lazy(() => import('./community/message-info')),

	ProfileActivity: lazy(() => import('./profile/activity')),
	ProfileNetwork: lazy(() => import('./profile/network')),
	ProfileAbout: lazy(() => import('./profile/about')),
	ProfileUpdate: lazy(() => import('./profile/edit')),

	AccountDelete: lazy(() => import('./support/delete-request')),
	Page404: lazy(() => import('./404')),
};
