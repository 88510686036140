/* eslint-disable no-constant-condition */
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import { InfiniteScrollProps } from '$types/components/PaginatedList';
import NoDataPage from '@components/shared/no-data/NoDataPage';
import styles from './infiniteScroll.module.scss';
import Loader from '@components/loaders';

export default function InfiniteScroll(props: InfiniteScrollProps) {
	const {
		list,
		isLoading,
		className,
		isFetching,
		page,
		threshold,
		skeleton,
		onPageChange,
		haveNext,
		noData,
		id = 'list',
		message,
		direction = 'regular',
		type = 'Infinite',
	} = props;
	const [loading, setLoading] = useState<boolean>(false);

	const containerRef = useRef<HTMLElement>(null);
	useEffect(() => {
		const handleScroll = debounce(() => {
			if (loading) return;
			const container = containerRef.current;
			const isScrollingToBottom =
				container &&
				(direction === 'regular'
					? container.scrollTop + container.clientHeight >= container.scrollHeight - 3100
					: container.scrollTop <= 0);

			if (isScrollingToBottom && haveNext) {
				onPageChange(page + 1);
				setLoading(true);
			}
		}, threshold);
		if (haveNext) {
			const container = containerRef.current;
			if (container) {
				container.addEventListener('scroll', handleScroll);
			}

			return () => {
				if (container) {
					container.removeEventListener('scroll', handleScroll);
				}
			};
		}
	}, [page, isLoading, loading, haveNext]);

	useEffect(() => {
		if (!isFetching) {
			setLoading(false);
		}
	}, [isFetching]);

	return (
		<>
			<article id={id} ref={containerRef} className={`${styles.container} ${className}`}>
				{isLoading ? skeleton : noData ? <NoDataPage data={message} /> : list}
				{isFetching && !isLoading ? <Loader.CircleLoader /> : null}
			</article>
		</>
	);
}
