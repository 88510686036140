import SkeletonAtom from './SkeletonAtom';
import styles from './styles.module.scss';

const InfoHeaderSkeleton = () => {
	return (
		<div className={styles.InfoHeader}>
			<div className={styles.header}>
				<SkeletonAtom type='avatar-xl' />
				<div className={styles.header_info}>
					<SkeletonAtom type='community-head-text' />
					<SkeletonAtom type='username' />
				</div>
			</div>
		</div>
	);
};

export default InfoHeaderSkeleton;
