import { createSlice } from '@reduxjs/toolkit';

const modal = createSlice({
	name: 'modal',
	initialState: {
		feedbackModal: false,
		signinModal: false,
		deleteUserModal: false,
		isSignin: false,
	},
	reducers: {
		toggleFeedbackModal: state => {
			state.feedbackModal = !state.feedbackModal;
		},
		toggleSigninModal: state => {
			state.signinModal = !state.signinModal;
		},
		toggleDeleteUserModal: state => {
			state.deleteUserModal = !state.deleteUserModal;
		},
	},
});

export const { toggleFeedbackModal, toggleSigninModal, toggleDeleteUserModal } = modal.actions;

export default modal.reducer;
