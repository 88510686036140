import { Theme } from '$types/components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Theme = {
	isDark: false,
};

const theme = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme: (state: Theme, action: PayloadAction<boolean>) => {
			state.isDark = action.payload;
			if (action.payload) document.body.classList.replace('light', 'dark');
			else document.body.classList.replace('dark', 'light');
			localStorage.setItem('theme', JSON.stringify({ isDark: action.payload }));
		},
	},
});

export const { setTheme } = theme.actions;
export default theme.reducer;
