import { toast } from "react-toastify";
import Compressor from "compressorjs";
//TYPES
import { FileUploadData, RemoveImageProps, UploadImages } from "$types/utils/mentions";
//COMPONENT
import dev from '@configs/env.config.json'

export const handleImage = (e: React.ChangeEvent<HTMLInputElement>, setFiles: React.Dispatch<React.SetStateAction<File[]>>, setImage: React.Dispatch<React.SetStateAction<string[]>>) => {
    const file: any = [];
    const urls: any = [];
    if (e.target.files) {
        for (let i = 0; i < e.target.files?.length; i++) {
            const _url = URL.createObjectURL(e.target.files[i]);
            file.push(e.target.files[i]);
            urls.push(_url);
        }
        setFiles(prev => [...prev, ...file])
        setImage(prev => [...prev, ...urls])
    }
};

export const checkWordTag = (e: any, setTagKey: React.Dispatch<React.SetStateAction<string>>) => {
    const text = e.target.value;
    let caretPos = e.target.selectionStart;
    let textArr = text.split(' ');
    if (caretPos !== text.length) {
        let wordPosition = 0;
        e.target.value.split(' ').forEach((word: string, i: number) => {
            let oldPosition = wordPosition;
            wordPosition += word.length;
            if (caretPos <= wordPosition && caretPos > oldPosition) {
                if (word[0] === '@') {
                    setTagKey(word.slice(1, word.length));
                    return true;
                } else {
                    return false;
                }
            }
            wordPosition++;
        });
    } else {
        let _textArr = e.target.value.split(' ');
        if (_textArr[textArr.length - 1][0] === '@') {
            let word = _textArr[textArr.length - 1];
            setTagKey(word.slice(1, word.length));
            return true;
        }
        return false;
    }
    return false;
};

export function compressImage(file: File): Promise<File> {
    return new Promise<File>((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            mimeType: 'image/webp',
            success: result => {
                resolve(new File([result], file.name, { type: result.type }));
            },
            error: (error: Error) => {
                reject(error);
            },
        });
    });
}

export const uploadAllImages = (uploadData: FileUploadData[]): Promise<any[]> => {
    return Promise.all(
        uploadData.map(async (file: FileUploadData) => {
            const response = await fetch(file.url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/webp',
                },
                body: file.image,
            });
            if (response.ok) {
                return dev.CLOUDFRONT_URL + file.fileName;
            } else {
                toast.error('Something went wrong');
                return [];
            }
        })
    );
};

export const handleUploadImages = async ({files, getUrls}: UploadImages) => {
    const compressPromises = files.map(file => {
        return compressImage(file);
    });
    const extensions: any[] = files.map(file => {
        return file?.name?.split('.').pop() ?? '';
    });
    const compressedImages = await Promise.all(compressPromises);

    const res = await getUrls({ extensions: extensions });

    const uploadUrls = (res as any).data.uploadData;

    const uploadData = uploadUrls.map((item: { fileName: string; url: string }, idx: number) => {
        return {
            image: compressedImages[idx],
            fileName: item.fileName,
            url: item.url,
        };
    });

    const result = await uploadAllImages(uploadData).then(res => {
        return res;
    });

    return result;
};

export const removeImage = ({image, imageFiles, images, handleFiles}: RemoveImageProps) => {
    let imageIndex = images.indexOf(image);
    if (imageIndex !== -1) {
        const tempImageFiles = imageFiles;
        const tempImages = images;
        tempImageFiles.splice(imageIndex, 1);
        tempImages.splice(imageIndex, 1);
        handleFiles(tempImageFiles, tempImages);
    }
};

export const uploadFile = async (uploadData: { file: File | null; url: string; fileName?: string }): Promise<string | null> => {
    const { file, url , fileName } = uploadData;
    
    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: file,
        });

         if (response.ok) {
                return dev.CLOUDFRONT_URL + fileName;
        } else {
            toast.error('Something went wrong');
            return null;
        }
    } catch (error) {
        toast.error('Error uploading file');
        return null;
    }
};