import { trendingCourse, searchCourses } from '$types/components/trendingCourse';
import api from '@store/api';

const courses = api.injectEndpoints({
	endpoints: builder => ({
		getTrendingCourses: builder.query<{ success: number; result: { data: trendingCourse[] } }, void>({
			query: () => ({
				url: 'https://www.cipherschools.com/api/v1/courses/trend',
			}),
			providesTags: ['courses'],
		}),
		searchCourses: builder.mutation<{success: number, result: searchCourses}, {query: string, page: number, limit: number}>({
			query: ({query, page, limit}) => ({
				url: `https://www.cipherschools.com/api/v1/users/search?query=${query}&filter=course&page=${page}&limit=${limit}`,
				method: 'GET',
			}),
			invalidatesTags: ['courses'],
		})
	}),
});

export const { useGetTrendingCoursesQuery, useSearchCoursesMutation } = courses;
