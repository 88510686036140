import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Reaction {
	postId: string;
	type: 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh';
}

const initialState = {
	reactions: [],
} as {
	reactions: Reaction[];
};

const reactions = createSlice({
	name: 'reactions',
	initialState,
	reducers: {
		setReactions: (state, action) => {
			state.reactions = action.payload;
		},
		updateReactions: (
			state,
			action: PayloadAction<{ type: 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh'; postId: string }>
		) => {
			const { postId } = action.payload;
			const index = state.reactions.findIndex(reaction => reaction.postId === postId);
			if (index !== -1) {
				state.reactions[index].type = action.payload.type;
			} else {
				state.reactions.push({ ...action.payload });
			}
		},
		removeReactions: (
			state,
			action: PayloadAction<{ postId: string}>
		) => {
			const { postId } = action.payload;
			const index = state.reactions.findIndex(reaction => reaction.postId === postId);
			if (index !== -1) {
				state.reactions = state.reactions.filter(function(reaction) {
					return reaction.postId !== postId
				})
			}else state.reactions;
		}
	},
});

export const { setReactions, updateReactions, removeReactions } = reactions.actions;

export default reactions.reducer;
