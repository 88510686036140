import dev from '@configs/env.config.json';

function linkifyText(text: string, validationArray?: { _id: string; username: string }[]): string {
	// Replace links, hashtags, and mentions with anchor tags
	const linkedText = text.replace(
		/(https?:\/\/[^\s]+)|((?:www\.)[^\s]+)|#(\w+)|@(\w+)/g,
		(match, link, link2, tag, username) => {
			if (link) {
				return `<a href="${link}" target='_blank'>${link}</a>`;
			} else if (link2) {
				return `<a href="${'https://' + link2}" target='_blank'>${link2}</a>`;
			} else if (tag) {
				return `<a href="${dev.PROD_JOIN_BASEURL}/search?tags=${tag}">${match}</a>`;
			} else if (username) {
				if (validationArray?.map(user => user._id).includes(username)) {
					const _username = validationArray.filter(user => user._id === username).map(user => user.username);
					return `<a href="${dev.PROD_JOIN_BASEURL}/profile/${_username}">@${_username}</a>`;
				} else {
					return `@${username}`;
				}
			}
			return match;
		}
	);

	return linkedText;
}

export default linkifyText;
