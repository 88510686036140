import api from '@store/api';
import { accountDelete } from '$types/components/request';

const request = api.injectEndpoints({
	endpoints: builder => ({
		deleteUser: builder.mutation<{ success: number; result: any }, accountDelete>({
			query: details => ({
				url: 'https://www.cipherschools.com/api/v1/requests',
				method: 'POST',
				body: details,
			}),
			invalidatesTags : () => ["delete-account"]
		}),
	}),
});

export const { useDeleteUserMutation } = request;
