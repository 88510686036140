import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Profile {
	activeSection: 'profile' | 'posts' | 'followers' | 'followings' | 'interactions';
	activeUserId: string | null;
	activeProfile: any;
	isLoading: boolean;
	isError: any;
}

const initialState: Profile = {
	isError: null,
	isLoading: false,
	activeUserId: null,
	activeProfile: null,
	activeSection: 'profile',
};

const profile = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		onStart: (state: any) => {
			state.isLoading = true;
			state.activeProfile = null;
			state.isError = null;
		},
		onSuccess: (state: any, action: PayloadAction<any>) => {
			state.isLoading = false;
			state.activeProfile = action.payload;
			state.isError = null;
		},
		onError: (state: any, action: PayloadAction<any>) => {
			state.isLoading = false;
			state.activeProfile = null;
			state.isError = action.payload;
		},
		setActiveSection: (state: any, action: PayloadAction<any>) => {
			state.activeSection = action.payload;
		},
		setActiveProfile: (state: any, action: PayloadAction<any>) => {
			state.activeProfile = action.payload;
		},
		updateActiveProfile: (state: any, action: PayloadAction<any>) => {
			state.activeChatInfo = { ...state.activeProfile, ...action.payload };
		},
		setActiveUserId: (state: any, action: PayloadAction<any>) => {
			state.activeUserId = action.payload;
		},
	},
});

export const { onStart, onSuccess, onError, setActiveProfile, setActiveSection, setActiveUserId, updateActiveProfile } =
	profile.actions;

export default profile.reducer;
