/* eslint-disable camelcase */
import { Message, MessageParams, MessageMediaResponse, chatProps } from '$types/components/message';
import { setParams } from 'src/utils/setParams';
import api from '@store/api';

const message = api.injectEndpoints({
	endpoints: builder => ({
		getCommunityMessages: builder.query<{success: number, result: chatProps}, { page: number; limit: number; groupId: string }>({
			query: params => ({
				url: `/v1/message/group?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if(currentCache.result.data?.[0]?.communityId === newItems.result.data?.[0]?.communityId){
					currentCache.result.next = newItems.result.next;
					currentCache.result.prev = newItems.result.prev;
					currentCache.result.total = newItems.result.total;
					currentCache.result.data.push(...newItems.result.data);
					// currentCache.result.data = processData(currentCache.result.data, newItems.result.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			  },
			providesTags: (result, error, { groupId }) => [{ type: 'get-messages', id: groupId }],
		}),
		addMessage: builder.mutation<Message, MessageParams[]>({
			query: body => {
				return {
					url: '/v1/message',
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body,
				};
			},
			invalidatesTags: ['send-message'],
		}),
		editMessage: builder.mutation<Message, { messageId: string; body: MessageParams }>({
			query: ({ messageId, body }) => ({
				url: `/v1/message/edit?${setParams({ messageId })}`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: ['edit-message'],
		}),
		deleteMessage: builder.mutation<{ messageIds: string[] }, { messageIds: string[] }>({
			query: ({ messageIds }) => ({
				url: `/v1/message`,
				method: 'PATCH',
				body: messageIds,
			}),
			invalidatesTags: ['delete-message'],
		}),
		getMedia: builder.query<
			{ success: number; result: MessageMediaResponse },
			{ communityId: string; page: number; limit: number; perPage: number }
		>({
			query: ({ communityId, page, limit, perPage }) => ({
				url: `/v1/message/medias?${setParams({ communityId, page, limit, perPage })}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			merge: (currentCache, newItems) => {
				if (currentCache.result.next && currentCache.result.next.page > 1) {
					currentCache.result.next = newItems.result.next;
					currentCache.result.prev = newItems.result.prev;
					currentCache.result.data.push(...newItems.result.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			// invalidatesTags: ['delete-message'],
		}),
		broadcastMessage: builder.mutation<any, any>({
			query: body => {
				return {
					url: '/v1/message/broadcast',
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body,
				};
			},
			// invalidatesTags: ['send-message'],
		}),
	}),
});

export const {
	useAddMessageMutation,
	useEditMessageMutation,
	useDeleteMessageMutation,
	useGetCommunityMessagesQuery,
	useGetMediaQuery,
	useBroadcastMessageMutation,
} = message;
