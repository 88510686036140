import React, { useContext, useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import EmojiPicker from '@components/dropdowns/EmojiDropdown';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import { Icon } from '@iconify/react';
import Select from 'react-select';

import { useGetImageUploadUrlMutation } from '@store/endpoints/post';
import { useGetCommunityByAdminQuery } from '@store/endpoints/community';
import { useBroadcastMessageMutation } from '@store/endpoints/message';
import dev from '@configs/env.config.json';
import styles from './styles.module.scss';
import { RootState } from '@store/store';
import { Data } from 'emoji-mart';
import SocketContext from '@store/socket/Context';

const animatedComponents = makeAnimated();

function BoradcastMessage(props: { handleClose: () => void }) {
	const user = useSelector((state: RootState) => state.user.user);
	const { socket } = useContext(SocketContext).SocketState;

	const [text, setText] = useState<any>('');
	const [boradCastImages, setBoradCastImages] = useState<string>('');
	const [selectedGroups, setSelectedGroups] = useState<any>([]);
	const [showEmoji, setShowEmoji] = useState(false);
	const [loading, setLoading] = useState(false);
	const [communityOptions, setCommunityOptions] = useState<{ value: string; label: string }[]>([]);
	const [showWarning, setShowWarning] = useState({ message: false, groups: false });

	const { data: communities, isFetching, isLoading } = useGetCommunityByAdminQuery(null);
	const [sendBroadcast] = useBroadcastMessageMutation();
	const [getImage] = useGetImageUploadUrlMutation();

	const EmojiHandler = (emoji: any) => {
		setText((prev: any) => prev + emoji.native);
	};

	const handleBrodcastImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			setLoading(true);
			const extension = file.name.split('.').pop();
			const res = await getImage({ extension: extension ?? '' });
			new Compressor(file, {
				quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
				mimeType: 'image/webp',
				async success(compressedResult) {
					const { fileName, url } = (res as any).data;

					const response = await fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'image/webp',
						},
						body: compressedResult,
					});
					if (response.ok) {
						setBoradCastImages(dev.CLOUDFRONT_URL + fileName);
					} else {
						toast.error('Something went wrong');
					}
					setLoading(false);
				},
				error(err) {
					setLoading(false);
				},
			});
		}
	};

	const handleBroadcast = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setLoading(true);
		if (!text || text.length === 0) {
			const _warning = { ...showWarning, message: true };
			setShowWarning(_warning);
			return;
		} else if (selectedGroups.length <= 1) {
			const _warning = { ...showWarning, groups: true };
			setShowWarning(_warning);
			return;
		}

		const data = {
			message: {
				image: boradCastImages,
				text: text,
				replyId: null,
				taggedUser: [],
				userId: user?._id,
			},
			communityIds: selectedGroups.map((item: { value: string; label: string }) => item.value),
		};

		sendBroadcast(data)
			.unwrap()
			.then(data => {
				setText('');
				setBoradCastImages('');
				setSelectedGroups([]);
				socket?.emit('community:broadcast:created', {
					messagesInfo: data.result,
					communityIds: selectedGroups.map((item: { value: string; label: string }) => item.value),
				});
				toast.success('Your message is Broadcasted');
				props.handleClose();
				setLoading(false);
			})
			.catch(err => {
				console.log(err);
				toast.error('Something went wrong!');
				setLoading(false);
			});
	};

	useEffect(() => {
		if (communities) {
			const data = communities?.result?.map(item => {
				return {
					value: item._id,
					label: item.name,
				};
			});
			setCommunityOptions(data ?? []);
		}
	}, [communities]);

	return (
		<div className={styles.boradcast}>
			<div className={styles.message}>
				<label htmlFor='broadcast-message'>Broadcast message</label>
				{boradCastImages !== '' ? (
					<div className={styles.images}>
						<div className={styles.singleImage}>
							<img src={boradCastImages} alt='input image' />
							<Icon icon='carbon:close-filled' className={styles.icon} onClick={() => setBoradCastImages('')} />
						</div>
					</div>
				) : null}
				{showWarning.message ? <p className={styles.warningText}>Please enter a valid message*</p> : null}
				<div className={styles.input}>
					<textarea
						rows={4}
						value={text}
						className={styles.inputField}
						onChange={e => {
							setText(e.target.value);
							const _warning = { ...showWarning, message: e.target.value.length > 0 ? false : true };
							setShowWarning(_warning);
						}}
					/>
					<div className={styles.icons}>
						<label htmlFor='broadcast-file-input' className={styles.icon}>
							<Icon icon='mdi:camera' className={styles.icon} />
							<input
								type='file'
								id='broadcast-file-input'
								style={{ display: 'none' }}
								onChange={e => handleBrodcastImage(e)}
							/>
						</label>
						<EmojiPicker EmojiHandler={EmojiHandler} placement={3} className={styles.emoji} />
					</div>
				</div>
			</div>
			<div className={styles.communities}>
				<p>Select groups</p>
				<br />
				{showWarning.groups ? <p className={styles.warningText}>please select more than 1 group*</p> : null}
				<Select
					closeMenuOnSelect={false}
					components={animatedComponents}
					// defaultValue={[colourOptions[4], colourOptions[5]]}
					classNames={{
						control: state => (state.isFocused ? '#F45046' : 'border-grey-300'),
					}}
					isMulti
					value={selectedGroups}
					options={communityOptions}
					onChange={e => {
						setSelectedGroups(e);
						const _warning = { ...showWarning, groups: e.length > 1 ? false : true };
						setShowWarning(_warning);
					}}
				/>
			</div>
			<div className={styles.footer}>
				<button className={styles.broadcastBtn} onClick={handleBroadcast} disabled={loading}>
					Broadcast
				</button>
			</div>
		</div>
	);
}

export default BoradcastMessage;
