import React from 'react';
import { Icon } from '@iconify/react';

//STYLES
import styles from './partials.module.scss';

function Loader() {
	return (
		<div className={styles.loader}>
			<Icon icon='svg-spinners:blocks-scale' className={styles.loaderIcon} />
		</div>
	);
}

export default Loader;
