import Loader from '@components/loaders';
import SkeletonAtom from '@skeletons/SkeletonAtom';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({
	alt,
	width,
	height,
	src,
	className,
	onClick,
}: {
	alt: string;
	width?: number;
	height?: number | string;
	src: string;
	className?: string;
	onClick?: () => void;
}) => {
	return (
		<LazyLoadImage
			alt={alt}
			effect='black-and-white'
			loading='lazy'
			src={src}
			width={width || '100%'}
			height={height || '100%'}
			placeholder={<Loader.ImageLoader />}
			className={className}
			onClick={onClick}
		/>
	);
};

export default LazyImage;
