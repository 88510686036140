import { RecommendedUsers } from '$types/store/endpoints/users';
import { Entity } from '$types/components/post';
import { FILTER } from '$types/pages/search';
import { User } from '$types/store/store';

import { setParams } from 'src/utils/setParams';

import api from '@store/api';

export interface UserResponse {
	result: User[];
	success: number;
}

interface UserParams {
	email?: string;
	userid?: string;
	username?: string;
}

interface UserUpdateField {
	firstName?: string;
	lastName?: string;
	bio?: string;
	social?: string;
	profile?: string;
}

const user = api.injectEndpoints({
	endpoints: build => ({
		getUser: build.query<UserResponse, UserParams>({
			query: params => ({
				url: `/v1/user/getuser?${setParams(params as { [key: string]: string })}`,
				method: 'GET',
			}),
			providesTags: (result, error, params) => [{ type: 'user', params }],
		}),
		updateUser: build.mutation<UserResponse, UserUpdateField>({
			query: user => ({
				url: '/v1/authuser/update-profile',
				method: 'PATCH',
				body: user,
			}),
			invalidatesTags: (result, error, user) => [{ type: 'user' }],
		}),
		updateUsername: build.mutation<UserResponse, { username: string }>({
			query: user => ({
				url: '/v1/authuser/update-username',
				method: 'PATCH',
				body: user,
			}),
			invalidatesTags: (result, error, user) => [{ type: 'user' }],
		}),
		checkUsername: build.mutation<any, { username: string }>({
			query: params => ({
				url: `/v1/authuser/check-username?${setParams(params)}`,
				method: 'Get',
			}),
			// invalidatesTags: (result, error, user) => [{ type: 'user' }],
		}),
		getLoggedInUser: build.query<UserResponse, null>({
			query: params => ({
				url: '/v1/authuser/me',
				method: 'GET',
			}),
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
		}),
		getLoggedUser: build.mutation<UserResponse, null>({
			query: params => ({
				url: '/v1/authuser/me',
				method: 'GET',
			}),
		}),
		getTrendingUsers: build.query<Entity[], { limit?: number; page?: number }>({
			query: params => ({
				url: `/v1/user/get-trending-users?${setParams(params)}`,
				method: 'GET',
			}),
			providesTags: ['trendingUsers'],
		}),
		getRecommendedUsers: build.mutation<RecommendedUsers, { userId: string }>({
			query: params => ({
				url: `/v1/user/recommend-users?${setParams(params)}`,
				method: 'GET',
			}),
		}),
		searchEntity: build.mutation<
			{ success: number; result: any },
			{ query: string; filter: keyof typeof FILTER; page: number; limit: number }
		>({
			query: params => ({
				url: `/v1/user/search?${setParams(params)}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetUserQuery,
	useUpdateUserMutation,
	useGetLoggedInUserQuery,
	useGetLoggedUserMutation,
	useGetTrendingUsersQuery,
	useCheckUsernameMutation,
	useSearchEntityMutation,
	useUpdateUsernameMutation,
	useGetRecommendedUsersMutation,
} = user;
