import React from 'react';
import styles from './partials.module.scss';
import { PollDataProps } from '$types/components/pollData';
import { Icon } from '@iconify/react';
import { RENDERCONTENT } from '@data/postData';

function PreviewPolls(props: {
	pollsData: PollDataProps;
	removePollData: () => void;
	switchContent: (val: string) => void;
	updateId: string | undefined;
	className?: string;
}) {
	const { question, options, expiredAt } = props.pollsData;
	const { removePollData, switchContent, updateId, className } = props;

	const calculateExiry = () => {
		const currDate = new Date();
		const expiryDate = new Date(expiredAt);
		const differenceMs = Math.abs(currDate.getTime() - expiryDate.getTime());

		// Convert milliseconds to days
		const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

		return differenceDays;
	};

	return (
		<article className={`${styles.pollsPreview} ${className}`}>
			<div className={styles.head}>
				<p className={styles.question}>Question: {question}</p>
				{!updateId && (
					<div>
						<button
							className={styles.removeBtn}
							onClick={() => {
								switchContent(RENDERCONTENT.polls);
							}}>
							<Icon icon='ic:outline-edit' />
						</button>
						<button className={styles.removeBtn} onClick={removePollData}>
							<Icon icon='mingcute:close-fill' />
						</button>
					</div>
				)}
			</div>
			<div className={styles.options}>
				{options
					// .sort((a, b) => a.optionNo - b.optionNo)
					.map((opt, i) => (
						<div key={i} className={styles.option}>
							<Icon icon='octicon:dot-16' className={styles.icon} />
							{opt.option}
						</div>
					))}
			</div>
			<p className={styles.expiry}>Poll available for next {calculateExiry()} days</p>
		</article>
	);
}

export default PreviewPolls;
