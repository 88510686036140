import styles from './styles.module.scss';

const SkeletonAtom = ({ type, display }: { type: string; display?: string }) => {
	return (
		<div
			className={`${styles[type]} ${styles.skeleton}`}
			style={{ display: display === 'inline' ? 'inline-flex' : 'block' }}></div>
	);
};

export default SkeletonAtom;
