import api from '@store/api';
import { setParams } from 'src/utils/setParams';

const authentication = api.injectEndpoints({
	endpoints: build => ({
		signin: build.mutation({
			query: credentials => ({
				url: '/v1/user/signin',
				method: 'POST',
				body: credentials,
			}),
			invalidatesTags: ['usersignin', 'follower', 'following'],
		}),
		signup: build.mutation({
			query: credentials => ({
				url: '/v1/user/signup',
				method: 'POST',
				body: credentials,
			}),
			invalidatesTags: ['usersignup'],
		}),
		googleSignin: build.mutation({
			query: credentials => ({
				url: '/v1/user/google-signin',
				method: 'POST',
				body: credentials,
			}),
			invalidatesTags: ['usersignin', 'follower', 'following'],
		}),
		logout: build.mutation<void, void>({
			query: () => ({
				url: '/v1/authuser/logout',
				method: 'DELETE',
			}),
			invalidatesTags: ['usersignin', 'follower', 'following'],
		}),
		verifyEmail: build.mutation<{ success: number; result: string }, null>({
			query: params => ({
				url: `/v1/authuser/verifyemail`,
				method: 'GET',
			}),
		}),
		getTagUsers: build.mutation<
			{
				success: number;
				result: { name: string; profile: string; username: string; _id: string }[];
			},
			{ key: string }
		>({
			query: params => ({
				url: `/v1/authuser/get-tags?${setParams(params)}`,
				method: 'GET',
			}),
		}),
		resetPassword: build.mutation<{ message: string }, { email: string }>({
			query: params => ({
				url: `/v1/user/reset-password?${setParams(params)}`,
				method: 'GET',
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useSigninMutation,
	useSignupMutation,
	useGoogleSigninMutation,
	useLogoutMutation,
	useVerifyEmailMutation,
	useResetPasswordMutation,
	useGetTagUsersMutation,
} = authentication;
