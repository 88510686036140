export const colors = [
	{
		back: 'var(--blue)',
		text: 'var(--blue-text)',
		shade: 'var(--blue-shade)',
	},
	{
		back: 'var(--green)',
		text: 'var(--green-text)',
		shade: 'var(--green-shade)',
	},
	{
		back: 'var(--aqua)',
		text: 'var(--aqua-text)',
		shade: 'var(--aqua-shade)',
	},
	{
		back: 'var(--yellow)',
		text: 'var(--yellow-text)',
		shade: 'var(--yellow-shade)',
	},
	{
		back: 'var(--orange)',
		text: 'var(--orange-text)',
		shade: 'var(--orange-shade)',
	},
	{
		back: 'var(--slateblue)',
		text: 'var(--slateblue-text)',
		shade: 'var(--slateblue-shade)',
	},
	{
		back: 'var(--violet)',
		text: 'var(--violet-text)',
		shade: 'var(--violet-shade)',
	},
];

export const sizes = {
	vsm: 26,
	sm: 32,
	md: 40,
	md2: 50,
	lg: 60,
	xl: 80,
	xxl: 100,
	xxxl: 120
};

export const positions = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'];
