import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'; // Ensure you have this import
import api from "@store/api"; // Make sure this import is correct

export interface FeedbackReq {
  type: string;
  message: string;
  userEntity?: object;
}

export interface FeedbackResponse {
  result: object;
  success : number
}

const feedbacks = api.injectEndpoints({
  endpoints: (build) => ({
    postFeedback: build.mutation<FeedbackResponse, FeedbackReq>({
      query: (feedback) => ({
        url: "/v1/feedbacks",
        method: "POST",
        body: feedback,
      }),
      invalidatesTags : () => ["users-feedbacks"]
    }),
  }),
});

export const { usePostFeedbackMutation } = feedbacks;
