/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

import styles from './trending.module.scss';
import { Post } from '$types/components/post';
import linkifyText from '@utilities/linkifyText';
import timeDifference from 'src/utils/formatDate';
import SkeletonAtom from '@skeletons/SkeletonAtom';
import LazyImage from '@components/shared/lazy-image';
import { useGetTrendingPostsQuery } from '@store/endpoints/post';

const PostComponent = ({ post }: { post: Post }) => {
	const { _id, communityId, createdAt, post_text, image_links, user, tags, taggedUser = [] } = post;
	const post_text_ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (post_text_ref.current) {
			post_text_ref.current.innerHTML = `<p>${linkifyText(post_text, taggedUser)}</p>`;
		}
	}, [post_text]);

	return (
		<li className={styles.cardItem} key={_id}>
			<div className={styles.cardContent}>
				<div className={styles.stats}>
					<Link to={`/profile/${user.username}`}>
						<p className={styles.tag}>
							&#x2022; <span>{user.username}</span>{' '}
						</p>
					</Link>
					<p className={styles.time}>
						&#x2022; <span>{timeDifference(createdAt)}</span>
					</p>
				</div>
				<Link to={`/post?postId=${_id}`}>
					<div className={styles.trendpost}>
						<div ref={post_text_ref} className={styles.content}></div>
					</div>
				</Link>
			</div>
			<aside className={styles.cardImage}>
				<Link to={`/post?postId=${_id}`}>
					{image_links && image_links.length ? (
						<LazyImage src={image_links[0]} height={'auto'} alt='post_image' className={styles.images} />
					) : null}
				</Link>
			</aside>
		</li>
	);
};

const TrendingPost = () => {
	const { data, isLoading } = useGetTrendingPostsQuery({ page: 1, limit: 10 });

	return (
		<div className={styles.card}>
			<div className={styles.cardHeader}>
				<Icon icon='ph:chart-bar-fill' className={styles.cardIcon} />
				<h2 className={styles.cardTitle}>Trending Posts</h2>
			</div>
			{isLoading ? (
				<>
					{Array(5)
						.fill(0)
						.map((e, i) => (
							<SkeletonAtom type='mini-post' key={i} />
						))}
				</>
			) : (
				<ul className={styles.cardList}>
					{data?.map(post => (
						<PostComponent post={post} key={post._id} />
					))}
				</ul>
			)}
		</div>
	);
};

export default TrendingPost;
