import React, { useState } from 'react';
import { Icon } from '@iconify/react';

import styles from './custom.module.scss';

interface FeedbackOption {
	value: string;
}

interface CustomDropdownProps {
	options: FeedbackOption[];
	placeholder: string;
	selectedValue: string;
	onSelect: (value: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, placeholder, selectedValue, onSelect }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOptionClick = (value: string) => {
		onSelect(value);
		setIsOpen(false);
	};

	return (
		<div className={styles.dropdown}>
			<div className={styles.dropdownHeader} onClick={() => setIsOpen(!isOpen)}>
				{selectedValue || placeholder}
				<Icon icon='mdi:arrow-down-drop' width='24' height='24' />
			</div>
			{isOpen && (
				<ul className={styles.dropdownList}>
					{options.map(option => (
						<li key={option.value} className={styles.dropdownListItem} onClick={() => handleOptionClick(option.value)}>
							{option.value}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default CustomDropdown;
