import styles from './avatar.module.scss';
import { ImageProps as Props } from '$types/components/partials';
import { RotatingTriangles } from 'react-loader-spinner';
import getRandomColor from '@utilities/getRandomColor';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { sizes } from '@utilities/values';
import Avatar from '@images/avatar.png';

function Image(props: Props) {
	const { src, alt, loader, errStyle, className, onClick } = props;
	const [error, setError] = useState<boolean>(false);
	const color = useMemo(() => getRandomColor(), [src]);
	const [loading, setLoading] = useState<boolean>(true);

	function renderChars() {
		let char = '';
		const endIndex = errStyle.chars || 1;
		const strings = alt?.replaceAll(/[^a-zA-Z0-9]+/g, ' ').split(' ');
		for (let i = 0; i < endIndex; i++) {
			if (strings?.[i]) char += strings?.[i].slice(0, 1);
		}
		return char;
	}

	function getClassName(prefix: string) {
		if (loading && !error) return styles[prefix + 'Loading'];
		else if (!loading && error) return styles[prefix + 'Error'];
		return '';
	}

	useEffect(() => {
		const eventListener = (event: MouseEvent) => {
			if (event.target instanceof HTMLElement && event.target.nodeName === 'IMG') {
				event.preventDefault();
			}
		};
		document.addEventListener('contextmenu', eventListener);
		return () => document.removeEventListener('contextmenu', eventListener);
	}, []);

	return (
		<Fragment>
			{!error && (
				<img
					src={src === 'none' ? Avatar : src}
					alt={alt}
					onLoad={() => {
						setLoading(false);
						setError(false);
					}}
					onError={() => {
						setError(true);
						setLoading(false);
					}}
					onClick={() => onClick?.()}
					height={sizes[loader]}
					width={sizes[loader]}
					className={`${styles.image} ${styles.avatar} ${className} ${getClassName('image')}`}
				/>
			)}
			{error && (
				<span
					className={`${styles.error} ${className} ${getClassName('error')}`}
					style={{
						color: errStyle.textColor || color.text,
						backgroundColor: errStyle.backColor || color.back,
						fontSize: errStyle.fontSize,
						height: sizes[loader],
						width: sizes[loader],
						borderRadius: '50%',
					}}
					onClick={() => onClick?.()}>
					{renderChars()}
				</span>
			)}
			<RotatingTriangles
				visible={true}
				height={sizes[loader]}
				width={sizes[loader]}
				wrapperClass={`${styles.loader} ${getClassName('loader')}`}
				colors={['var(--primary)', 'var(--blue)', 'var(--slateblue)']}
			/>
		</Fragment>
		// <LazyLoadImage src={src === '' ? avatar : src} alt={alt || 'Cipher schools'} className={`${styles.avatar} ${className}`} />
	);
}

export default Image;
