// import React, { useEffect, useRef, ReactNode } from 'react';

// interface ClickAwayProps {
//   onClickAway?: () => void;
//   className?:string,
//   children: ReactNode;
// }

// const ClickAway: React.FC<ClickAwayProps> = ({ onClickAway, className, children }) => {
//   const containerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
//         if (onClickAway) {
//           onClickAway();
//         }
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [onClickAway]);

//   return <div className={className}ref={containerRef}>{children}</div>;
// };

// export default ClickAway;

import React, { Component, RefObject } from 'react';

interface ClickAwayListenerProps {
	nodeRef?: RefObject<HTMLElement>;
	className?: string;
	children: React.ReactNode;
	onClickAway: () => void;
}

class ClickAwayListener extends Component<ClickAwayListenerProps> {
	// Stores a reference to the containing node
	// This is used when checking where a click is coming from
	private node: HTMLElement | null = null;

	handleClickAway = (e: MouseEvent) => {
		// Check if the click came from inside the click away container
		// If it did, do nothing
		if (this.node && this.node.contains(e.target as Node)) return;

		// Check if the click came from inside an additional node reference
		// If it did, do nothing
		if (this.props.nodeRef && this.props.nodeRef.current && this.props.nodeRef.current.contains(e.target as Node)) return;

		// Otherwise, the click happened outside of the click away container
		// So let's execute the click away function
		this.props.onClickAway();
	};

	componentDidMount() {
		// When the component mounts, register a click event that processes the click away
		window.addEventListener('click', this.handleClickAway, true);
		// window.addEventListener('touch', this.handleClickAway, true);
	}

	componentWillUnmount() {
		// When the component unmounts, remove the click event that processes the click away
		window.removeEventListener('click', this.handleClickAway, true);
	}

	render() {
		return (
			<div ref={ref => (this.node = ref)} className={this.props.className}>
				{this.props.children}
			</div>
		);
	}
}

export default ClickAwayListener;
