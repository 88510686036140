import TrendingPost from './partials/trendingPost';
import TrendingCourse from './partials/trendingCourse';

const HomeSidebar = () => {
	return (
		<>
			<TrendingPost />
			<TrendingCourse />
		</>
	);
};

export default HomeSidebar;
