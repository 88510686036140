import { createSlice } from '@reduxjs/toolkit';

const context = createSlice({
	name: 'context',
	initialState: {
		title: 'Home',
	},
	reducers: {
		setTitle(state, action) {
			state.title = action.payload;
		},
	},
});

export const { setTitle } = context.actions;

export default context.reducer;
