import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';

import SocketContext from '@store/socket/Context';
import { RootState } from '@store/store';
import {
	useSearchJoinedOrPublicCommunityMutation,
	useGetJoinedCommunitiesQuery,
	useGetPublicCommunitiesQuery,
} from '@store/endpoints/community';

import InfiniteScroll from '@components/paginated-list/InfiniteScroll';
import BoradcastMessage from '@components/cards/broadcast-message';
import InputField from '@components/partials/inputField';
import ListItemSkeleton from '@skeletons/ListItem';
import Modal from '@components/partials/Modal';
import { ListItem } from '@components/list';

import styles from './sidebar.module.scss';

const limit = 20;

export const convertToEntity = (data: any) => {
	return data?.map((item: any) => ({
		_id: item._id,
		profile: item.profile,
		description: item.description,
		isJoined: item.isMember,
		name: item.name,
		username: item.community_type,
		userId: item.userId,
		memberCount: item.memberCount,
	}));
};

const CommunitySideBar = () => {
	const user = useSelector((state: RootState) => state.user.user);
	const chats = useSelector((state: RootState) => state.chats);
	const [searchField, setSearchField] = useState<string>('');
	const [showBroadcast, setShowBroadcast] = useState(false);
	const { socket } = useContext(SocketContext).SocketState;
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchData, setSearchData] = useState<any>();
	const [page2, setPage2] = useState<number>(1);
	const [page, setPage] = useState<number>(1);

	const {
		data: joinedCommunities,
		isLoading: joinedLoading,
		isFetching: joinedFetching,
		refetch,
	} = useGetJoinedCommunitiesQuery({ id: user?._id || '', page: page2, limit }, { skip: !user });
	const {
		data: communities,
		isFetching,
		isLoading,
	} = useGetPublicCommunitiesQuery({ page, limit }, { skip: user !== undefined });
	const [search] = useSearchJoinedOrPublicCommunityMutation();

	const handleSearch = () => {
		setIsSearch(true);
		if (searchField === '') {
			setSearchData(undefined);
			setSearchField('');
			setIsSearch(false);
			return;
		}
		search({ q: searchField, page: 1, limit })
			.unwrap()
			.then(data => {
				setSearchData(data);
			});
		setLoading(true);
	};

	useEffect(() => {
		socket?.on('last-activity', (data: { cid: string[] | string }) => {
			const flag = Array.isArray(data.cid) ? data.cid.includes(chats?.activeChatId ?? '') : data.cid === chats.activeChatId;
			if (flag) {
				setPage2(1);
				window.scrollTo(0, 0);
				refetch();
			}
		});
	}, [socket]);

	return (
		<>
			<div className={styles.topHeader}>
				<InputField
					holder='Search Joined Community'
					value={searchField}
					className={styles.searchbar}
					setValue={setSearchField}
					fieldType='search'
					icon='ant-design:search-outlined'
					onSubmit={handleSearch}
					empty={true}
				/>
				<Modal
					showModal={showBroadcast}
					setShowModal={setShowBroadcast}
					className='boradcastModal'
					placement='top'
					heading='Broadcast'
					overlayClassName='boradcastModal-overlay'>
					<BoradcastMessage handleClose={() => setShowBroadcast(false)} />
				</Modal>
			</div>
			<div className={styles.body}>
				{isSearch ? (
					<div className={styles.communityHeading}>
						<h3>Search Results</h3>
						<Icon
							icon='ic:outline-cancel'
							width={20}
							onClick={() => {
								setSearchData(undefined);
								setSearchField('');
								setIsSearch(false);
							}}
						/>
					</div>
				) : !user ? (
					<div className={styles.communityHeading}>
						<h3>Popular Communities</h3>
					</div>
				) : (
					<div className={styles.communityHeading}>
						<h3>Joined Communities</h3>
					</div>
				)}
				{isSearch ? (
					<>
						{isLoading ? (
							<ListItemSkeleton count={5} />
						) : (
							<article className={styles.communityList}>
								{searchData?.data?.map((community: any) => (
									<ListItem key={community._id} type='COMMUNITY' item={community} />
								))}
							</article>
						)}
					</>
				) : user ? (
					<InfiniteScroll
						list={joinedCommunities?.data?.map((community: any) => (
							<ListItem
								key={community._id}
								type='COMMUNITY'
								item={community}
								className={styles.listItem}
								onClick={() => {
									setSearchField('');
									setIsSearch(false);
								}}
							/>
						))}
						page={page2}
						threshold={600}
						isLoading={joinedLoading}
						isFetching={joinedFetching}
						className={styles.container}
						message='no Communities joined yet'
						noData={joinedCommunities?.total === 0}
						skeleton={<ListItemSkeleton count={5} />}
						haveNext={joinedCommunities && joinedCommunities.total > joinedCommunities.data.length}
						onPageChange={(num: number) => setPage2(num)}
					/>
				) : (
					<InfiniteScroll
						list={communities?.data?.map((community: any) => (
							<ListItem key={community._id} type='COMMUNITY' item={community} className={styles.item} />
						))}
						page={page}
						isLoading={isLoading}
						isFetching={isFetching}
						threshold={600}
						className={styles.container}
						message='no communities available yet'
						noData={communities?.total === 0}
						skeleton={<ListItemSkeleton count={10} />}
						haveNext={communities && communities.total > communities.data.length}
						onPageChange={(num: number) => setPage(num)}
					/>
				)}
			</div>
		</>
	);
};

export default CommunitySideBar;
