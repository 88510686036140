import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';

import { CommunityParams } from '$types/components/community';
import { RootState } from '$types/store/store';

import { useGetMultipleImageUploadUrlMutation } from '@store/endpoints/post';
import { useUpdateCommunityMutation } from '@store/endpoints/community';

import { handleUploadImages } from '@utils/mentions';
import Image from '@components/partials/Avatar';

import styles from './headers.module.scss';
import { updateActiveChatInfo } from '@store/reducers/chats';

const superUser = 'support@cipherschools.com';

const InfoHeader = () => {
	const user = useSelector((state: RootState) => state.user.user);
	const chat = useSelector((state: any) => state.chats);

	const navigate = useNavigate();

	const [name, setName] = useState(chat.activeChatInfo?.name);
	const [editName, setEditName] = useState(false);

	const communityNameRef = useRef<any>();
	const dispatch = useDispatch();

	const [updateCommunity] = useUpdateCommunityMutation();
	const [getImagesUrl] = useGetMultipleImageUploadUrlMutation();

	const handleUpdateCommunityName = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault;
		if (chat.activeChatInfo?.name === name) {
			setEditName(false);
			return;
		}
		const _data: { communityId: string; body: CommunityParams } = {
			communityId: chat.activeChatInfo?._id,
			body: {
				name,
				profile: chat.activeChatInfo?.profile,
				description: chat.activeChatInfo?.description,
				community_type: chat.activeChatInfo?.community_type,
			},
		};
		updateCommunity(_data)
			.unwrap()
			.then(data => {
				toast.success('Group name updated successfully');
				dispatch(updateActiveChatInfo({ ..._data.body }));
			})
			.catch(error => toast.error(`Can't update community name 😥`));

		setEditName(false);
	};

	const updateImage = (newImage: string) => {
		const _data: { communityId: string; body: CommunityParams } = {
			communityId: chat.activeChatInfo?._id,
			body: {
				name,
				profile: newImage,
				description: chat.activeChatInfo?.description,
				community_type: chat.activeChatInfo?.community_type,
			},
		};
		updateCommunity(_data)
			.unwrap()
			.then(data => {
				toast.success('Group image updated successfully');
				dispatch(updateActiveChatInfo({ ..._data.body }));
			})
			.catch(error => toast.error(`Can't update community profile image😥`));

		setEditName(false);
	};

	const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			let imagesLinks = await handleUploadImages({ files: [file], getUrls: getImagesUrl });

			if (imagesLinks && imagesLinks.length) {
				const profileImage = imagesLinks[0];
				updateImage(profileImage);
				localStorage.setItem('lastCommunityProfile', profileImage);
			} else {
				toast.error('Something went wrong');
			}
		}
	};

	return (
		<header className={styles['desctiption-header']}>
			<button className={styles.back} onClick={() => navigate(-1)}>
				<Icon icon='material-symbols:arrow-back' className={styles.icon} />
				Back
			</button>
			<div className={styles['description-main']}>
				<div className={styles.avatar}>
					<Image
						src={chat.activeChatInfo?.profile}
						alt={chat.activeChatInfo?.name}
						loader='xxxl'
						errStyle={{ fontSize: '3rem' }}
					/>

					{chat.activeChatInfo?.userId === user?._id || user?.email === superUser ? (
						<>
							<label htmlFor='image' className={styles.camera}>
								<Icon icon='bxs:camera' className={styles.icon} />
								<input type='file' id='image' accept='image/*' hidden onChange={handleImage} />
							</label>
						</>
					) : null}
				</div>

				<div className={styles.info}>
					{!editName ? (
						<h2 className={`${styles.title} ${editName ? styles['edit-title'] : null}`} contentEditable={editName}>
							{chat.activeChatInfo?.name}
							{(chat.activeChatInfo?.userId === user?._id || user?.email === superUser) && (
								<button onClick={() => setEditName(prev => !prev)}>
									<Icon icon='material-symbols:edit' />
								</button>
							)}
						</h2>
					) : (
						<div className={styles['edit-info']}>
							<input type='text' value={name} onChange={e => setName(e.target.value)} />
							<button ref={communityNameRef} onClick={handleUpdateCommunityName}>
								<Icon icon='charm:tick' />
							</button>
						</div>
					)}
					{chat.activeChatInfo?.community_type === 'private' ? (
						<p>
							<Icon icon='material-symbols:lock' className={styles.lock} /> Private Group
						</p>
					) : null}
					<h3 className={styles.subtitle}>{chat.activeChatInfo?.memberCount} Members</h3>
				</div>
			</div>
		</header>
	);
};

export default InfoHeader;
