import { NotificationResponse } from '$types/components/notification';
import api from '@store/api';
import { setParams } from '@utils/setParams';

type notificationType = 'post' | 'comment' | 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh' | 'follow' | 'welcome' | 'community' | 'announcement';

const notification = api.injectEndpoints({
	endpoints: builder => ({
		getNotifications: builder.query<NotificationResponse, {page?:number, limit?:number}>({
			query: (params) => ({
				url: `/v1/notification?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({endpointName}) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if(currentCache.next && currentCache.next.page>1){
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				}else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			  },
		
			providesTags: ['notifications'],
		}),
		getTodayNotifications: builder.query<NotificationResponse, {page?:number, limit?:number}>({
			query: (params) => ({
				url: `/v1/notification/recent?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({endpointName}) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if(currentCache.next && currentCache.next.page>1){
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				}else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			  },
		
			providesTags: ['notifications'],
		}),
		postNotification: builder.mutation<string, { entityId: string; type: notificationType; recieverId?: string }>({
			query: params => ({
				url: '/v1/notification',
				method: 'POST',
				body: params,
			}),
		}),
		announcementNotification: builder.mutation<string, { entityId: string; type: notificationType; recieverId?: string }>({
			query: params => ({
				url: '/v1/notification',
				method: 'POST',
				body: params,
			}),
		}),
		communityNotification: builder.mutation<string, { entityId: string; type: notificationType; recieverId?: string }>({
			query: params => ({
				url: '/v1/notification',
				method: 'POST',
				body: params,
			}),
		}),
		getUnreadNotifications: builder.query<{ unreadNotifications: number }, void>({
			query: () => ({
				url: '/v1/notification/unread',
				method: 'GET',
			}),
			providesTags: ['unreadNotifications'],
		}),
	}),
});

export const { useGetNotificationsQuery, useGetTodayNotificationsQuery, usePostNotificationMutation, useCommunityNotificationMutation, useGetUnreadNotificationsQuery, useAnnouncementNotificationMutation } = notification;
