import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import React, { lazy, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import { setActiveProfile, setActiveUserId } from '@store/reducers/profile';
import { toggleSigninModal } from '@store/reducers/modal';
import { useGetUserQuery } from '@store/endpoints/user';
import { setTitle } from '@store/reducers/context';
import { RootState } from '@store/store';

import ProfileHeaderSkeleton from '@skeletons/ProfileHeader';
import ProfileHeader from '@components/atoms/ProfileHeader';
import HomeSidebar from '@components/shared/home-sidebar';
import ProfileStage from '@components/atoms/ProfileStage';
import CreatePost from '@components/cards/create-post';
import Modal from '@components/partials/Modal';

import styles from './profile.module.scss';
import Header from '@components/shared/header';

function ProfileLayout() {
	const user = useSelector((state: RootState) => state.user.user);

	const { username } = useParams();
	const dispatch = useDispatch();

	const MainHeader = lazy(() => import('@components/shared/header'));
	const LeftSidebar = lazy(() => import('@shared/left-sidebar'));

	const { data, isLoading } = useGetUserQuery({ username: username }, { skip: !username });

	const [open, setOpen] = useState<boolean>(false);

	const setShowModal = () => dispatch(toggleSigninModal());

	const handlePost = () => {
		if (!user) {
			dispatch(toggleSigninModal());
		} else {
			setOpen(true);
		}
	};

	useEffect(() => {
		if (data?.success === 200) {
			dispatch(setTitle(`${data.result?.[0]?.name} Activity`));
			dispatch(setActiveProfile(data.result[0]));
			dispatch(setActiveUserId(data.result[0]._id));
		}
	}, [data]);

	return (
		<>
			<header className={styles.header}>
				<MainHeader onClick={setShowModal} />
			</header>
			<main className={styles.main}>
				<aside className={styles.sidebar}>
					<LeftSidebar />
				</aside>
				<section className={styles.container}>
					<article className={styles.content}>
						{isLoading ? (
							<ProfileHeaderSkeleton />
						) : (
							<ProfileHeader className={styles.profileHeader}>
								<ProfileStage />
							</ProfileHeader>
						)}
						<Outlet />
					</article>
					<div className={'postIcon'} onClick={handlePost}>
						<Icon icon='fa6-regular:paper-plane' />
					</div>
					<article className={styles.sidebar}>
						<HomeSidebar />
					</article>
				</section>
			</main>

			<Modal setShowModal={setOpen} showModal={open} className='postModal' placement='top'>
				<CreatePost setShowModal={setOpen} />
			</Modal>
		</>
	);
}

export default React.memo(ProfileLayout);
