import React, { useEffect, useState } from 'react';
//STYLES
import styles from './styles.module.scss';
import './index.css';
import { Content, Footer, Header, Loader, PostIcon } from '../../partials';
import ImagesList from '../../partials/imagesList';
import Modal from '@components/partials/Modal';
import Alert from '@components/alerts/Alert';
import { Audience } from '$types/components/post';
import {
	useGetMultipleImageUploadUrlMutation,
	useCreatePostMutation,
	useUpdatePostMutation,
	useGetPostQuery,
} from '@store/endpoints/post';
import { handleImage, handleUploadImages } from '@utils/mentions';
import { HandleSubmitProps } from '../..';
import { PollDataProps } from '$types/components/pollData';
import PreviewPolls from '../../partials/PreviewPolls';
import { toast } from 'react-toastify';

type PostInput = {
	loading: boolean;
	initial?: {
		post_text: string;
		share_with: Audience;
		image_links: string[];
	};
	updateId?: string;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	handleSubmit: (props: HandleSubmitProps) => void;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	switchContent: (val: string) => void;
	pollsData: PollDataProps;
	removePollData: () => void;
	setPostText: React.Dispatch<React.SetStateAction<string>>;
	post_text: string;
	setPollData: (data: PollDataProps) => void;
};

function PostInput(props: PostInput) {
	const {
		loading,
		initial,
		updateId,
		setShowModal,
		handleSubmit,
		open,
		setOpen,
		switchContent,
		pollsData,
		removePollData,
		setPostText,
		post_text,
		setPollData,
	} = props;

	const { data } = useGetPostQuery({ postId: updateId ?? '' }, { skip: !updateId });

	const [audience, setAudience] = useState<Audience>(initial?.share_with ?? 'everyone');
	const [images, setImages] = useState<string[]>(initial?.image_links ?? []);
	const [files, setFiles] = useState<File[]>([]);

	useEffect(() => {
		if (data) {
			setPostText(data.post_text);
			if (data?.image_links?.length) {
				setImages(data.image_links ?? []);
			}
			if (data.poll) {
				setPollData({
					userId: data.poll.userId,
					question: data.poll.question,
					expiredAt: data.poll.expiredAt,
					options: data.pollOptions?.map(item => ({ _id: item._id, option: item.option })) ?? [],
				});
			}
		}
	}, [data]);

	const handleFiles = (files: File[], images: string[]) => {
		setFiles(files);
		setImages(images);
	};

	const renderPolls = () => {
		const flag = pollsData.question === '' || pollsData.options.length === 0;
		return !flag;
	};

	return (
		<>
			<article className={styles['cardcontentarea']}>
				<Header audience={audience} handleChange={(e: any) => setAudience(e)} />
				<main className={styles.mainContent}>
					{loading && <Loader />}
					<Content postText={post_text} setPostText={setPostText} className='content' />
					{renderPolls() ? (
						<PreviewPolls
							pollsData={pollsData}
							removePollData={removePollData}
							switchContent={switchContent}
							updateId={updateId}
							className={'polls'}
						/>
					) : null}
					{images.length ? (
						<ImagesList
							images={images}
							className='images'
							imageFiles={files}
							updateId={updateId}
							handleFiles={handleFiles}
						/>
					) : null}
				</main>
				<Footer
					setOpen={setOpen}
					updateId={updateId}
					postText={post_text}
					setPostText={setPostText}
					imagesCount={images.length}
					switchContent={switchContent}
					handleImage={e => handleImage(e, setFiles, setImages)}
					canUploadImage={pollsData.question === '' || pollsData.options.length === 0}
				/>
			</article>
			<PostIcon onClose={setShowModal} submit={() => handleSubmit({ post_text, images, files, audience })} />
			<Modal setShowModal={setOpen} showModal={open} className='verificationModal' placement='center'>
				<Alert />
			</Modal>
		</>
	);
}

export default PostInput;
