import React, { useState } from 'react';

import { Audience } from '$types/components/post';
import styles from './styles.module.scss';
import { Icon } from '@iconify/react';
import ClickAway from '@components/partials/clickaway';

interface DropdownProps {
	options: { title: string; icon?: string }[];
	selectedOption?: string;
	className?: string;
	isDisabled?: boolean;
	onSelectOption: (selectedOption: any) => void;
}

type Option = {
	title: Audience;
	icon: string;
};

const Dropdown: React.FC<DropdownProps> = ({ options, onSelectOption, ...others }) => {
	const [selectedOption, setSelectedOption] = useState<string | undefined>(others.selectedOption || '');
	const [show, setShow] = useState<boolean>(false);

	const handleOptionSelect = (option: Audience) => {
		setSelectedOption(option);
		onSelectOption(option);
		setShow(false);
	};

	return (
		<ClickAway className={`${styles.dropdown} ${others?.className}`} onClickAway={() => setShow(false)}>
			<button
				disabled={others.isDisabled}
				className={styles.dropdownToggle}
				type='button'
				data-toggle='dropdown'
				onClick={() => setShow(prev => !prev)}>
				<span>{selectedOption || 'Select an option'}</span>
				<Icon icon='mingcute:down-line' />
			</button>
			<ul className={show ? styles.dropdownMenu : styles.hidden}>
				{options.map((option: any, index: number) => (
					<li key={index}>
						<button className={styles.dropdownItem} onClick={() => handleOptionSelect(option.title)}>
							{option.icon && <Icon icon={option.icon} />}
							<span>{option.title}</span>
						</button>
					</li>
				))}
			</ul>
		</ClickAway>
	);
};

export default Dropdown;
