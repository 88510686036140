import { Entity, Post, PostParams, postsProps } from '$types/components/post';
import { _STAGES } from '$types/pages/profile';
import api from '@store/api';
import { setParams } from 'src/utils/setParams';

interface PostLikes {
	_id: string;
	postId: string;
	type: 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh';
	user: Entity;
	topLikes?: string[];
}

interface UserReaction {
	_id: string;
	postId: string;
	type: 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh';
}

type LikeType = 'Total' | 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh';

const postLikes = api.injectEndpoints({
	endpoints: builder => ({
		getUsersReaction: builder.query<UserReaction[], null>({
			query: () => ({
				url: `/v1/like/melikes`,
				method: 'GET',
			}),
		}),
		getPostLikes: builder.query<postsProps, { postId: string; page?: number; limit?: number; type: LikeType }>({
			query: params => ({
				url: `/v1/like/getlikesonpost?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			// transformResponse: (response: postsProps) => response.data.map((like: PostLikes) => ({ ...like.user, type: like.type }))
			providesTags: (result, error, { postId }) => [{ type: 'likes', postId }],
		}),
		getLikedPosts: builder.query<postsProps, { uid?: string; page?: number; limit?: number }>({
			query: params => ({
				url: `/v1/like/getlikedposts?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},

			providesTags: (result, error) => result?.data?.map(({ _id }) => ({ type: 'likes', _id })) || [],
		}),
		likePost: builder.mutation<PostLikes, { postId: string; type: 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh' }>({
			query: params => ({
				url: `/v1/like/add-like-on-post?`,
				method: 'POST',
				body: params,
			}),
			invalidatesTags: (result, error, { postId }) => [{ type: 'likes', postId }],
		}),
		unlikePost: builder.mutation<PostLikes, { postId: string }>({
			query: params => ({
				url: `/v1/like/remove-like-from-post?${setParams(params)}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, { postId }) => [{ type: 'likes', postId }],
		}),
		updateLike: builder.mutation<PostLikes, { postId: string; type: 'Like' | 'Love' | 'Celebrate' | 'Support' | 'Laugh' }>({
			query: params => ({
				url: `/v1/like/update-like-on-post`,
				method: 'PATCH',
				body: params,
			}),
			invalidatesTags: (result, error, { postId }) => [{ type: 'likes', postId }],
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetUsersReactionQuery,
	useGetPostLikesQuery,
	useLikePostMutation,
	useUnlikePostMutation,
	useUpdateLikeMutation,
	useGetLikedPostsQuery,
} = postLikes;
