export function addToLocalStorage(key: string, value: any) {
	if (typeof value === 'object') {
		localStorage.setItem(key, JSON.stringify(value));
	} else {
		localStorage.setItem(key, value);
	}
}

export function getFromLocalStorage(key: string) {
	const value = localStorage.getItem(key);
	if (!value) {
		return null;
	}

	try {
		return JSON.parse(value);
	} catch (error) {
		return value;
	}
}

export function removeFromLocalStorage(key: string) {
	localStorage.removeItem(key);
}
