import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './inputField.module.scss';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { toggleSigninModal } from '@store/reducers/modal';
import EmojiPicker from '@components/dropdowns/EmojiDropdown';
import { toast } from 'react-toastify';
import { InputFieldProps as Props } from '$types/components/partials';
import ClickAway from './clickaway';

function InputField(props: Props) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const dispatch = useDispatch();

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (props.fieldType !== 'search') {
			if (!props.value) {
				toast.error('Kindly enter a valid value');
				return;
			}
		}
		if (props.disabled) {
			dispatch(toggleSigninModal());
			toast.error('Kindly Sign in to continue');
			return;
		}
		if (props.onSubmit) props.onSubmit(props.value);
		!props.empty && props.value && props.setValue?.('');
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSubmit(e);
		}
	};

	const EmojiHandler = (emoji: any) => {
		props.setValue?.(props.value + emoji.native);
	};

	return (
		<Fragment>
			{props.children ? (
				<ClickAway onClickAway={() => setIsOpen(false)}>
					<input
						value={props.value}
						placeholder={props.holder}
						className={styles.container}
						spellCheck='false'
						autoComplete='off'
						autoCorrect='off'
						disabled={props.disabled}
						readOnly={props.readonly}
						onFocus={() => {
							setIsOpen(true);
							props.onFocus?.();
						}}
						onChange={e => props.setValue?.(e.target.value)}
					/>
					<div className={`${styles.options} ${isOpen ? styles.opened : ''}`}>{props.children}</div>
				</ClickAway>
			) : (
				<fieldset className={`${styles.fieldset} `}>
					{props.title && <label>{props.title}</label>}
					<div className={`${styles.inputCont} ${props.className}`}>
						<input
							ref={props.inputRef}
							type={props.type || 'text'}
							value={props.value}
							onChange={e => props.setValue?.(e.target.value)}
							onKeyDown={handleKeyDown}
							placeholder={props.holder}
							className={`${styles.input}`}
							disabled={props.disabled}
						/>
						<div className={styles.actionBtns}>
							{props.emoji && <EmojiPicker EmojiHandler={EmojiHandler} />}
							{props.icon && (
								<button type='submit' onClick={handleSubmit}>
									<Icon icon={props.icon} className={styles.icon} />
								</button>
							)}
						</div>
					</div>
				</fieldset>
			)}
		</Fragment>
	);
}

export default InputField;
