import { useDispatch, useSelector } from 'react-redux';
import React, { lazy, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Icon } from '@iconify/react';

import { toggleSigninModal, toggleFeedbackModal } from '@store/reducers/modal';
import { RootState } from '@store/store';

import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
import HomeSidebar from '@components/shared/home-sidebar';
import FeedbackModal from '@components/cards/feedback';
import FeatureCard from '@components/cards/new-feature';
import CreatePost from '@components/cards/create-post';
import Header from '@components/shared/header';
import Modal from '@components/partials/Modal';

import styles from './home.module.scss';

function Home() {
	const feedback = useSelector((state: RootState) => state.modal.feedbackModal);
	const user = useSelector((state: RootState) => state.user.user);
	const dispatch = useDispatch();

	const LeftSidebar = lazy(() => import('@shared/left-sidebar'));

	const [showPollFeatureCard, setShowPollFeatureCard] = useState(true);
	const [open, setOpen] = useState<boolean>(false);

	const setShowModal = () => dispatch(toggleSigninModal());

	const handlePost = () => {
		if (!user) dispatch(toggleSigninModal());
		else setOpen(true);
	};

	const handleToggleModal = () => dispatch(toggleFeedbackModal());

	// un-comment when polls feature is fully launched
	// useEffect(() => {
	// 	const introducePollsFeature = () => {
	// 		const res = getFromLocalStorage('IntroducePollsFeature');
	// 		if (res) {
	// 			setShowPollFeatureCard(false);
	// 		} else {
	// 			setShowPollFeatureCard(true);
	// 		}
	// 	};

	// 	setTimeout(() => {
	// 		introducePollsFeature();
	// 	}, 10000);
	// }, []);

	return (
		<>
			<header className={styles.header}>
				<Header onClick={() => setShowModal()} />
			</header>
			<main className={styles.main}>
				<aside className={styles.sidebar}>
					<LeftSidebar />
				</aside>
				<section className={styles.container}>
					<article className={styles.content}>
						<Outlet />
					</article>
					<div className={'postIcon'} onClick={handlePost}>
						<Icon icon='fa6-regular:paper-plane' />
					</div>
					<article className={styles.sidebar}>
						<HomeSidebar />
					</article>
				</section>
			</main>

			{/* Un-comment this later when the polls feature is fully launched */}
			{/* <Modal
				setShowModal={setShowPollFeatureCard}
				showModal={showPollFeatureCard}
				className='postModal'
				placement='center'
				showHeader={false}>
				<FeatureCard
					heading='Hear others opinion!'
					content='Now you can use Plutonn to create a poll and know what others think about your opinion'
					onCancel={() => {
						addToLocalStorage('IntroducePollsFeature', { show: true });
						setShowPollFeatureCard(false);
					}}
					onProceed={() => {
						addToLocalStorage('IntroducePollsFeature', { show: true });
						setShowPollFeatureCard(false);
						setOpen(true);
					}}
				/>
			</Modal> */}

			<Modal setShowModal={setOpen} showModal={open} className='postModal' placement='top'>
				<CreatePost setShowModal={setOpen} />
			</Modal>
			<Modal className='postModal' showModal={feedback} setShowModal={handleToggleModal} heading='Send Feedback'>
				<FeedbackModal />
			</Modal>
		</>
	);
}

export default React.memo(Home);
