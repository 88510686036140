type Type = 'USER' | 'COMMUNITY_MEMBERS'|'COMMUNITY' | 'POST' | 'TAGS'|'ANNOUNCEMENT' | 'COMMUNITY_DEFAULT' | 'COMMENTS';

export function dropdownType( type: Type) {
	switch (type) {
		case 'USER':
			return [
				{
					title: 'Edit',
				},
				{
					title: 'Delete',
				},
			];
		case 'COMMUNITY_MEMBERS':
			return [
				{
					title: 'Kick',
				},
				{
					title: 'Report',
				},
			]
		case 'COMMUNITY':
			return [
				{
					title: 'Edit',
				},
				{
					title: 'Share',
				},
				{
					title: 'Archive',
				},
				{
					title: 'Delete',
				},
			]
		case 'COMMUNITY_DEFAULT':
			return [
				{
					title: 'Info',
					icon: "bi:info-circle"
				},
				{
					title: 'Pin',
					icon: 'clarity:pin-solid'
				},
				{
					title: 'UnPin',
					icon: 'ri:unpin-fill'
				},
			]
		case 'POST':
			return [
				{
					title: 'Edit'
				},
				{
					title: 'Delete'
				},
				{
					title: 'Report'
				}
			]
		case 'ANNOUNCEMENT':
			return [
				{
					title: 'Edit'
				},
				{
					title: 'Delete'
				}
			]
		case 'TAGS':
			return [
				{
					title: 'Report'
				}
			]
		case 'COMMENTS':
			return [
				{
					title: 'Report'
				},
				{
					title: 'Delete'
				}
			]
		default:
			return [];
	}
}
