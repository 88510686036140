import React, { useState } from 'react';
import styles from './style.module.scss';

import MailGif from '@icons/mailing.gif';
import ImageLoader from '@components/partials/ImageLoader';

import { useVerifyEmailMutation } from '@store/endpoints/auth';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import { getCookies, setCookies } from '@utils/cookie';

function Alert() {
	const [sentStatus, setSentStatus] = useState<boolean>(getCookies('verification-email') === 'sent');
	const [loading, setLoading] = useState<boolean>(false);
	const [sendVerification] = useVerifyEmailMutation();

	const handleResendRequest = () => {
		setLoading(true);
		sendVerification(null)
			.unwrap()
			.then(data => {
				setLoading(false);
				setSentStatus(true);
				toast.success('Verification email sent');
				setCookies('verification-email', 'sent', 0.04);
			})
			.catch(error => {
				setLoading(false);
				toast.error('Something went wrong!');
			});
	};

	return (
		<div className={styles.container}>
			<h2>Verify your account</h2>
			<p>Account verification link has been sent to the email address you provided</p>
			<div className={styles.imageContainer}>
				<ImageLoader src={MailGif} className={styles.mailGif} alt='mailGif' />
			</div>
			{loading ? (
				<div className={styles.loadingIcon}>
					<RotatingLines strokeColor='var(--primary)' strokeWidth='5' animationDuration='0.75' width='26' visible={true} />
				</div>
			) : sentStatus ? (
				<h4 className={styles.resendBtn}>{`Email sent, Check your Spam/Inbox`}</h4>
			) : (
				<button className={styles.resendBtn} onClick={handleResendRequest}>{`Didn't get the mail, Send again`}</button>
			)}
		</div>
	);
}

export default Alert;
