import React from 'react';

import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import reportWebVitals from './tests/reportWebVitals';

import '@styles/index.scss';
import { Provider } from 'react-redux';
import { store } from '@store/store';
import SocketContextComponent from '@store/socket/Component';
import { GoogleOAuthProvider } from '@react-oauth/google';
import dev from '@configs/env.config.json';

const root = document.getElementById('root') as HTMLElement;

const clientId = dev.GOOGLE_CLIENT_ID_OFFICIAL;

if (root.hasChildNodes()) {
	hydrate(
		<React.StrictMode>
			<GoogleOAuthProvider clientId={clientId}>
				<SocketContextComponent>
					<Provider store={store}>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</Provider>
				</SocketContextComponent>
			</GoogleOAuthProvider>
		</React.StrictMode>,
		root
	);
} else {
	render(
		<React.StrictMode>
			<GoogleOAuthProvider clientId={clientId}>
				<SocketContextComponent>
					<Provider store={store}>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</Provider>
				</SocketContextComponent>
			</GoogleOAuthProvider>
		</React.StrictMode>,
		root
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
