
export type _STAGES = 'POST'|'INTERACTIONS'|'PROFILE'|'SKILLS'|'PROFILE-EDIT'|'NETWORK'


export const STAGES = {
	profile: 'Profile',
	posts: 'Posts',
	interactions: 'Interactions',
};

export interface ProfileProps {
	children?: React.ReactNode;
}