/* eslint-disable camelcase */
import { Entity, Post, PostParams, postsProps } from '$types/components/post';
import { setParams } from 'src/utils/setParams';
import api from '@store/api';

const post = api.injectEndpoints({
	endpoints: builder => ({
		getPosts: builder.query<postsProps, { page: number; limit: number }>({
			query: params => ({
				url: `/v1/post/getposts?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},

			providesTags: (result, error) => result?.data.map(({ _id }) => ({ type: 'post1', postId: _id })) || [],
		}),
		getUserPosts: builder.query<postsProps, { page?: number; limit?: number; userId?: string }>({
			query: params => ({
				url: `/v1/post/get-entity-posts?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},

			providesTags: (result, error, { userId }) => [{ type: 'post', id: userId }],
		}),
		createPost: builder.mutation<Post, PostParams>({
			query: params => {
				return {
					url: '/v1/post/createpost',
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: params,
				};
			},
			invalidatesTags: ['post'],
		}),
		getImageUploadUrl: builder.mutation<{ url: string; fileName: string }, { extension: string }>({
			query: params => ({
				url: `/v1/post/image-upload-url?${setParams(params)}`,
				method: 'POST',
			}),
		}),
		getMultipleImageUploadUrl: builder.mutation<{ urls: string[]; fileNames: string[] }, { extensions: string[] }>({
			query: params => ({
				url: `/v1/post/multi-image-upload-url`,
				method: 'POST',
				body: { extentions: params.extensions },
			}),
		}),
		getTrendingPosts: builder.query<Post[], { page?: number; limit?: number }>({
			query: params => ({
				url: `/v1/post/get-trending-posts?${setParams(params)}`,
				method: 'GET',
			}),
			providesTags: ['trendingPosts'],
		}),
		getPost: builder.query<Post, { postId: string }>({
			query: params => ({
				url: `/v1/post/getpost?${setParams(params)}`,
				method: 'GET',
			}),
			providesTags: (result, error, { postId }) => [{ type: 'post', id: postId }],
		}),
		findPost: builder.mutation<Entity, { postId: string }>({
			query: params => ({
				url: `/v1/post/getpost?${setParams(params)}`,
				method: 'GET',
			}),
			invalidatesTags: ['post'],
		}),
		getCommunityPost: builder.query<Post, { postId: string; cid: string }>({
			query: params => ({
				url: `/v1/post/getpost?${setParams(params)}`,
				method: 'GET',
			}),
			providesTags: (result, error, { postId }) => [{ type: 'post', id: postId }],
		}),
		updatePost: builder.mutation<Post, { postId: string; body: PostParams }>({
			query: ({ postId, body }) => ({
				url: `/v1/post/update-post?${setParams({ postId })}`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: ['post'],
		}),
		deletePost: builder.mutation<{ message: string }, { postId: string }>({
			query: ({ postId }) => ({
				url: `/v1/post/deletepost?${setParams({ postId })}`,
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'post' }],
		}),
		getUploadFileUrl: builder.mutation<{url : string, fileName : string},{extension : string}>({
            query: (params) => ({
				url : `/v1/post/file-upload-url?${setParams(params)}`,
				method : 'POST',
			})
		})
	}),
});

export const {
	useGetPostsQuery,
	useFindPostMutation,
	useGetUserPostsQuery,
	useCreatePostMutation,
	useGetTrendingPostsQuery,
	useGetImageUploadUrlMutation,
	useGetMultipleImageUploadUrlMutation,
	useGetPostQuery,
	useGetCommunityPostQuery,
	useUpdatePostMutation,
	useDeletePostMutation,
	useGetUploadFileUrlMutation
} = post;
