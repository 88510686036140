import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

import { ListItemProps, ListProps } from '$types/components/list';

import { setActiveChatId, setActivePage } from '@store/reducers/chats';
import { RootState } from '@store/store';

import ClickAway from '@components/partials/clickaway';
import ListItemSkeleton from '@skeletons/ListItem';
import Image from '@components/partials/Avatar';

import styles from './list.module.scss';

type List = {
	total: number;
	next?: {
		page: number;
		limit: number;
	};
	prev?: {
		page: number;
		limit: number;
	};
	data: any[];
};

export const ListItem = (props: ListItemProps) => {
	const { className, item, dropdown, type, fontWeight, time, children, triggers, isAdmin, onClick } = props;
	const { _id, name, profile, username, description, isJoined, ...others } = item;
	const user = useSelector((state: RootState) => state.user.user);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleClick = () => {
		if (type === 'USER') navigate(`/profile/${username}`);
		else if (type === 'COMMUNITY') {
			// dispatch(setActiveChatId(_id));
			dispatch(setActivePage('chats'));
			navigate(`/community/chats?cid=${_id}`);
			onClick && onClick();
		}
	};

	return (
		<li key={_id} className={`${styles.cardItem} ${className}`}>
			<div onClick={handleClick} className={styles.item}>
				<Image src={profile} alt={name || 'User'} loader='md' errStyle={{ fontSize: '2.3rem' }} className={styles.icon} />
				{type === 'COMMUNITY' && others.community_type === 'private' ? (
					<Icon icon='material-symbols:lock' className={styles.lock} />
				) : null}
				<div className={styles.info}>
					{type === 'USER' && (
						<span className={styles.username}>
							@{username} &#x2022; {time && <span className={styles.time}>{time}</span>}
						</span>
					)}
					<h4 className={styles.name} style={fontWeight ? { fontWeight: fontWeight } : undefined}>
						{name}
						{/* {time && <span className={styles.time}>{time}</span>} */}
					</h4>
					{type === 'COMMUNITY' ? (
						user && others.unreadMessages > 0 ? (
							<h3 className={`${styles.subtitle} ${styles.unread}`}>{`${others.unreadMessages} New ${
								others.unreadMessages > 1 ? 'messages' : 'message'
							}`}</h3>
						) : (
							<h3 className={styles.subtitle}>{others?.memberCount} Members</h3>
						)
					) : null}
				</div>
			</div>

			<div className={styles.actions}>
				{isAdmin ? <p className={styles.adminText}>Admin</p> : null}

				{!isAdmin && dropdown && triggers ? (
					<ClickAway onClickAway={() => setShowDropdown(false)}>
						<button className={styles.dots} onClick={() => setShowDropdown(prev => !prev)}>
							<Icon icon={'mdi:dots-vertical'} color='black' width={24} height={24} />
							<ul className={`${styles.list} ${showDropdown ? styles.showdots : ''} ${styles.options__container}`}>
								{dropdown.map((item, i) => (
									<li key={i} onClick={() => triggers(item.title)} className={styles.item__options}>
										{item.title}
									</li>
								))}
							</ul>
						</button>
					</ClickAway>
				) : (
					children
				)}
			</div>
		</li>
	);
};

const List = ({ className, itemClassName, data, type, community }: ListProps) => {
	return (
		<div className={`${styles.card} ${className}`}>
			{data ? (
				data?.map(item => (
					<ListItem item={item} type={type || 'USER'} key={item._id} community={community} className={itemClassName} />
				))
			) : (
				<ListItemSkeleton count={4} />
			)}
		</div>
	);
};

export default List;
