import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Chats {
	activePage: any,
	activeReplyMsg: any,
	activeChatInfo: any,
	activeChatId: string,
    unreadMessages: number,
    unreadChatCount: number,
}

const initialState: Chats = {
	activePage: null,
	activeChatId: '',
    unreadMessages: 0,
    unreadChatCount: 0,
	activeChatInfo: null,
	activeReplyMsg:  null,
};

const chats = createSlice({
	name: 'chats',
	initialState,
	reducers: {
		setActivePage: (state: any, action: PayloadAction<any>) => {
			state.activePage = action.payload;
		},
		setActiveChatInfo: (state: any, action: PayloadAction<any>) => {
			state.activeChatInfo = action.payload;
		},
		updateActiveChatInfo: (state: any, action: PayloadAction<any>) => {
			state.activeChatInfo = {...state.activeChatInfo, ...action.payload};
		},
		setActiveReplyMsg: (state: any, action: PayloadAction<any>) => {
			state.activeReplyMsg=action.payload;
		},
		setActiveChatId: (state: Chats, action: PayloadAction<string>) => {
			state.activeChatId = action.payload;
		},
        setUnreadChatCount: (state: Chats, action: PayloadAction<{unread: number, chat: number}>) => {
			state.unreadChatCount = action.payload.chat;
            state.unreadMessages = action.payload.unread
		},
	},
});7

export const { setActivePage, setActiveChatId, updateActiveChatInfo, setUnreadChatCount, setActiveChatInfo, setActiveReplyMsg } = chats.actions;

export default chats.reducer;
