import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import { toggleDeleteUserModal } from '@store/reducers/modal';
import { RootState } from '$types/store/store';
import { useDeleteUserMutation } from '@store/endpoints/request';
import styles from './account.module.scss';
import { useNavigate } from 'react-router-dom';

interface ReqText {
	platform: string;
	type: string;
	reason: string;
	message: string;
	contact?: string;
	userEntity: {
		name?: string;
		email?: string;
	};
}

const AccountDelete: React.FC = () => {
	const user = useSelector((state: RootState) => state.user.user);

	const [reqText, setReqText] = useState<ReqText>({
		platform: 'plutonn',
		type: 'account-deletion',
		reason: '',
		message: '',
		contact: '',
		userEntity: {
			name: user?.name || '',
			email: user?.email || '',
		},
	});

	const [postRequest, { isLoading }] = useDeleteUserMutation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (user?.email) {
			setReqText((prev: ReqText) => ({
				...prev,
				name: user.name || '',
				email: user.email,
				contact: user.mobile || '',
			}));
		}
	}, [user]);

	const isEmpty = () => {
		return !reqText.userEntity.name || !reqText.userEntity.email || !reqText.reason;
	};

	const handleOnSubmit = async () => {
		const loadingToastId = toast.loading('Submitting Request');
		if (!isEmpty()) {
			const newRequest = {
				...reqText,
				reason: reqText.reason || '',
				message: reqText.message || '',
				userEntity: {
					name: reqText.userEntity.name,
					email: reqText.userEntity.email,
				},
			};

			try {
				const response = await postRequest(newRequest).unwrap();
				if (response.success === 201) {
					toast.update(loadingToastId, {
						render: 'Account deletion request sent,  Out team will inform you in 24 hours.',
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						autoClose: 2500,
					});
					setTimeout(() => {
						navigate('/');
					}, 1500);
				} else {
					toast.update(loadingToastId, {
						render: 'Something went wrong',
						type: toast.TYPE.ERROR,
						isLoading: false,
						autoClose: 2500,
					});
				}
				dispatch(toggleDeleteUserModal());
			} catch (error) {
				toast.update(loadingToastId, {
					render: 'Something went wrong',
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 2500,
				});
			}
		} else {
			toast.update(loadingToastId, {
				render: 'Some fields are missing',
				type: toast.TYPE.WARNING,
				isLoading: false,
				autoClose: 2500,
			});
		}
	};

	return (
		<article className={styles['container']}>
			<h3>Account Deletion Request</h3>
			<div className={styles['sf-textfield-row']}>
				<input
					placeholder='Email Address'
					value={reqText.userEntity.email}
					disabled={user !== undefined}
					className={styles['input']}
					onChange={e => setReqText(prev => ({ ...prev, email: e.target.value }))}
				/>
			</div>
			<div className={styles['sf-textfield-msg']}>
				<textarea
					className={styles['input']}
					value={reqText.reason}
					onChange={e => setReqText(prev => ({ ...prev, reason: e.target.value }))}
					placeholder='Enter your Reason here...'
				/>
			</div>
			<div className={styles['sf-textfield-msg']}>
				<textarea
					className={styles['input']}
					value={reqText.message}
					onChange={e => setReqText(prev => ({ ...prev, message: e.target.value }))}
					placeholder='Enter your message here...'
				/>
			</div>
			<div className={styles['sf-btns-cont']}>
				<button
					className={`${styles['submit-btn']} ${styles['sf-btn-active']}`}
					onClick={() => handleOnSubmit()}
					disabled={isLoading}>
					{isLoading ? <Icon icon='line-md:loading-loop' /> : 'Submit'}
				</button>
			</div>
		</article>
	);
};

export default AccountDelete;
