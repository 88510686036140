import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';

import CustomDropdown from '@components/atoms/Dropdown';
import styles from './feedback.module.scss';

import { usePostFeedbackMutation } from '@store/endpoints/users-feedbacks';
import { toggleFeedbackModal } from '@store/reducers/modal';
import { RootState } from '$types/store/store';

interface FeedbackOption {
	value: string;
}

interface ReqText {
	type: string;
	name: string;
	email: string;
	contact: string;
	message?: string;
}

const FeedbackModal: React.FC = () => {
	const [reqText, setReqText] = useState<ReqText>({
		type: '',
		name: '',
		email: '',
		contact: '',
		message: '',
	});

	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.user.user);
	const [postFeedback, { isLoading }] = usePostFeedbackMutation();

	const FeedbackList: FeedbackOption[] = [
		{ value: 'Feature Requests' },
		{ value: 'Bug Reports' },
		{ value: 'Questions' },
		{ value: 'General' },
		{ value: 'Others' },
	];

	useEffect(() => {
		if (user?.email) {
			setReqText((prev: ReqText) => ({
				...prev,
				name: user.name || '',
				email: user.email,
				contact: user.mobile || '',
			}));
		}
	}, [user]);

	const defaultProps = {
		options: FeedbackList,
		getOptionLabel: (option: FeedbackOption) => option.value,
	};

	const isEmpty = () => {
		return !reqText.name || !reqText.email || !reqText.message || !reqText.type;
	};

	const handleOnSubmit = async () => {
		const loadingToastId = toast.loading('Submitting Feedback');
		if (!isEmpty()) {
			const newFeedback = {
				type: reqText.type || '',
				message: reqText.message || '',
				userEntity: {
					name: reqText.name,
					email: reqText.email,
					contact: reqText.contact,
					id: user?._id || null,
				},
			};

			try {
				const response = await postFeedback(newFeedback).unwrap();
				setReqText({
					type: 'Others',
					name: '',
					email: '',
					contact: '',
					message: '',
				});
				if (response.success === 201) {
					toast.update(loadingToastId, {
						render: 'Feedback Submitte Successfully! ',
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						autoClose: 2500,
					});
				} else {
					toast.update(loadingToastId, {
						render: 'Something went wrong',
						type: toast.TYPE.ERROR,
						isLoading: false,
						autoClose: 2500,
					});
				}
				dispatch(toggleFeedbackModal());
			} catch (error) {
				// dispatch(services.failureAlert("Your feedback can't be submitted at this point of time"));
				toast.update(loadingToastId, {
					render: 'Something went wrong',
					type: toast.TYPE.ERROR,
					isLoading: false,
					autoClose: 2500,
				});
			}
		} else {
			toast.update(loadingToastId, {
				render: 'Some fields are missing',
				type: toast.TYPE.WARNING,
				isLoading: false,
				autoClose: 2500,
			});
		}
	};

	const handleSelectChange = (value: string) => {
		setReqText({ ...reqText, type: value });
	};

	return (
		<article className={styles.container}>
			<CustomDropdown
				placeholder='Feedback type'
				options={FeedbackList}
				selectedValue={reqText.type}
				onSelect={handleSelectChange}
			/>
			<div className={styles['sf-textfield-row']}>
				<input
					placeholder='Enter Your Name'
					disabled={user !== undefined}
					value={reqText.name}
					className={styles['sf-textfield']}
					onChange={e => setReqText(prev => ({ ...prev, name: e.target.value }))}
				/>
			</div>
			<div className={styles['sf-textfield-row']}>
				<input
					placeholder='Email Address'
					value={reqText.email}
					disabled={user !== undefined}
					className={styles['sf-textfield']}
					onChange={e => setReqText(prev => ({ ...prev, email: e.target.value }))}
				/>
				<input
					placeholder='Contact Number'
					value={reqText.contact}
					className={styles['sf-textfield']}
					onChange={e => setReqText(prev => ({ ...prev, contact: e.target.value }))}
				/>
			</div>
			<div className={styles['sf-textfield-msg']}>
				<textarea
					className={styles['sf-msg-cont']}
					value={reqText.message}
					onChange={e => setReqText(prev => ({ ...prev, message: e.target.value }))}
					placeholder='Enter your message here...'
				/>
			</div>
			<div className={styles['sf-btns-cont']}>
				<button
					className={`${styles['sf-msg-btn']} ${styles['sf-btn-active']}`}
					onClick={() => handleOnSubmit()}
					disabled={isLoading}>
					{isLoading ? <Icon icon='line-md:loading-loop' /> : 'Submit Now'}
				</button>
			</div>
		</article>
	);
};

export default FeedbackModal;
