import React from 'react';
import { Icon } from '@iconify/react';

import styles from './Advertisement.module.scss';
import Logo from '@images/plutonn.png';

interface AdvertisementProps {
	title: string;
	mainText: string;
	buttonText: string;
	buttonLink: string;
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Advertisement = (props: AdvertisementProps) => {
	const { title, mainText, buttonText, buttonLink, showModal, setShowModal } = props;
	return showModal ? (
		<div className={styles.modal}>
			<div className={styles.banner}>
				<div className={styles.text}>
					<div>
						<img src={Logo} alt='' />
						<h1>{title}</h1>
					</div>
					<p>{mainText}</p>
				</div>
				<a href={buttonLink}>
					<button>{buttonText}</button>
				</a>
				<Icon className={styles.icon} icon='iconoir:cancel' width={24} height={24} onClick={() => setShowModal(false)} />
			</div>
		</div>
	) : null;
};

export default Advertisement;
