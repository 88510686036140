import React from 'react';
import { Icon } from '@iconify/react';
//SWIPER
import { A11y, EffectFade, Navigation, Pagination, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
//TYPES
import { CreatePostImagesPorps } from '$types/components/cards';
//COMPONENTS
import ImageLoader from '@components/partials/ImageLoader';
import { removeImage } from '@utils/mentions';
//STYLES
import styles from './partials.module.scss';
import './index.css';

function ImagesList(props: CreatePostImagesPorps) {
	const { imageFiles, images, handleFiles, updateId, className } = props;

	return (
		<section className={`${styles.images} ${className}`}>
			<Swiper
				modules={[Navigation, Pagination, A11y, EffectFade, FreeMode]}
				spaceBetween={10}
				slidesPerView={2}
				freeMode={true}
				navigation
				className={styles.swiper}
				grabCursor={true}
				pagination={{ clickable: true }}>
				{images.map((image: any) => (
					<SwiperSlide key={image}>
						<div className={styles.image__container}>
							<div className={styles.image__wrapper}>
								<ImageLoader src={image} alt='Post Image' className={styles.image} />
							</div>
							{!updateId && (
								<div className={styles.cross} onClick={() => removeImage({ image, images, imageFiles, handleFiles })}>
									<Icon icon='material-symbols:close-rounded' />
								</div>
							)}
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
}

export default React.memo(ImagesList);
