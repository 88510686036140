import React, { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import { CreatePostContentProps } from '$types/components/cards';

import { useGetTagUsersMutation } from '@store/endpoints/auth';
import Image from '@components/partials/Avatar';
import { checkWordTag } from '@utils/mentions';
import styles from './partials.module.scss';
import './index.css';
import { useDebounce } from '@hooks/useDebounce';

const _default = {
	control: {
		fontSize: 14,
		fontWeight: 'normal',
		backgroundColor: '#fff',
	},

	'&multiLine': {
		control: {
			fontFamily: 'monospace',
			minHeight: 63,
		},
		highlighter: {
			padding: 9,
			border: '1px solid transparent',
			boxSizing: 'border-box',
			overflow: 'hidden',
			height: 120,
		},
		input: {
			padding: 9,
			overflow: 'auto',
			height: 120,
			borderRadius: '8px',
			fontFamily: 'sans-serif',
			lineHeight: '16px',
		},
	},

	suggestions: {
		zIndex: 5,
		list: {
			backgroundColor: 'white',
			display: 'flex',
			flexDirection: 'column',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 14,
			zIndex: 5,
		},
		item: {
			padding: '5px 15px',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#cee4e5',
			},
			zIndex: 5,
		},
	},
};

function Content(props: CreatePostContentProps) {
	const { postText, setPostText, className } = props;
	const [loading, setLoading] = useState(false);

	const [suggestions, setSuggestions] = useState<{ name: string; profile: string; username: string; _id: string }[]>([]);
	const [tagKey, setTagKey] = useState('');
	const inputRef = useRef<any>();
	const debouncedSearch = useDebounce(tagKey);
	const [getTags] = useGetTagUsersMutation();

	useEffect(() => {
		const getUserSuggestions = (key: string) => {
			setLoading(true);
			getTags({ key })
				.unwrap()
				.then((res: any) => {
					if (res.success === 200) {
						setSuggestions([...res.result]);
					}
				});
			setLoading(false);
		};
		getUserSuggestions(debouncedSearch);
	}, [debouncedSearch]);

	return (
		<article className={`${styles['cardtextarea']} ${className}`}>
			<MentionsInput
				value={postText}
				style={_default}
				inputRef={inputRef}
				onKeyDown={e => checkWordTag(e, setTagKey)}
				onChange={e => {
					if (e.target.value.split(' ').length <= 400) {
						setPostText(e.target.value);
					}
				}}
				allowSuggestionsAboveCursor={true}
				placeholder={"What's on your mind?"}>
				<Mention
					trigger='@'
					data={(search, callback) => {
						setTagKey(search);
						const returnArr = suggestions.map(user => ({
							id: user._id,
							display: user.username,
							profile: user.profile,
							name: user.name,
						}));
						callback(returnArr);
					}}
					isLoading={loading}
					renderSuggestion={entry => {
						const user = entry as any;
						return (
							<div
								className={styles.suggestedUser}
								style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
								<div className={styles.left}>
									<Image
										src={user?.profile || 'none'}
										alt={user?.name || 'profile'}
										loader='vsm'
										errStyle={{ fontSize: '1.8rem' }}
									/>
								</div>
								<div
									className={styles.right}
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										justifyContent: 'flex-start',
									}}>
									<p className={styles.heading}>{user.display}</p>
									<p className={styles.subheading}>{user.name}</p>
								</div>
							</div>
						);
					}}
					displayTransform={(id, display) => `@${display}`}
				/>
			</MentionsInput>
		</article>
	);
}

export default Content;
