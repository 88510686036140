import React, { useEffect, useState } from 'react';
import Picker from '@emoji-mart/react';
import { Icon } from '@iconify/react';
import data from '@emoji-mart/data';

import styles from './styles.module.scss'; // Import the SCSS file
import ClickAway from '@components/partials/clickaway';

interface EmojiPickerProps {
	EmojiHandler: (emoji: string) => void;
	placement?: 1 | 2 | 3 | 4;
	className?: any;
}

const EmojiPicker: React.FC<EmojiPickerProps> = props => {
	const { EmojiHandler, placement, className } = props;
	const [isOpen, setIsOpen] = useState(false);

	//Placement 1 = top left
	//Placement 2 = top right
	//Placement 3 = bottom left
	//Placement 4 = bottom right

	const [position, setPosition] = useState('top-left');

	useEffect(() => {
		switch (placement) {
			case 1:
				setPosition('top-left');
				break;
			case 2:
				setPosition('top-right');
				break;
			case 3:
				setPosition('bottom-left');
				break;
			case 4:
				setPosition('bottom-right');
				break;
			default:
				setPosition('top-left');
				break;
		}
	}, [placement]);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<ClickAway onClickAway={() => setIsOpen(false)}>
			<div className={`${styles.emojiPicker} ${className} ${styles[position]}`}>
				<button className={styles.actions} onClick={handleToggle}>
					<Icon icon='mdi:emoticon-outline' color='var(--primary)' className={`${styles.icon}`} />
				</button>
				{isOpen && (
					<Picker
						data={data}
						onEmojiSelect={EmojiHandler}
						// set='apple'
						emojiSize={24}
						title='Pick your emoji…'
						// emoji='point_up'
					/>
				)}
			</div>
		</ClickAway>
	);
};

export default EmojiPicker;
