import styles from './imageloader.module.scss';
import { ImageLoaderProps as Props } from '$types/components/partials';
import { RotatingLines } from 'react-loader-spinner';
import { Fragment, useEffect, useState } from 'react';

function ImageLoader(props: Props) {
	const { src, alt, className, onClick } = props;
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	function getClassName(prefix: string) {
		if (loading && !error) return styles[prefix + 'Loading'];
		else if (!loading && error) return styles[prefix + 'Error'];
		return '';
	}

	useEffect(() => {
		const eventListener = (event: MouseEvent) => {
			if (event.target instanceof HTMLElement && event.target.nodeName === 'IMG') {
				event.preventDefault();
			}
		};
		document.addEventListener('contextmenu', eventListener);
		return () => document.removeEventListener('contextmenu', eventListener);
	}, []);

	return (
		<Fragment>
			{!error && (
				<img
					src={src}
					alt={alt}
					onLoad={() => {
						setLoading(false);
						setError(false);
					}}
					onError={() => {
						setError(true);
						setLoading(false);
					}}
					height='100%'
					width='100%'
					onClick={onClick}
					className={`${styles.image} ${className} ${getClassName('image')}`}
				/>
			)}
			{error && (
				<span
					className={`${styles.error} ${className} ${getClassName('error')}`}
					style={{
						color: 'var(--black)',
						backgroundColor: 'rgba(0, 0, 0, 0.15)',
						fontSize: 'var(--fs-para)',
						height: 340,
						width: '100%',
					}}>
					{"Image can't load"}
				</span>
			)}
			{loading && (
				<div className={styles.ImageCont}>
					<RotatingLines strokeColor='var(--primary)' strokeWidth='5' animationDuration='0.75' width='56' visible={true} />
				</div>
			)}
		</Fragment>
	);
}

export default ImageLoader;
