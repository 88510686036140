import { Outlet, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { lazy, useEffect } from 'react';

import { useGetCommunityMutation } from '@store/endpoints/community';
import { setActiveChatId, setActiveChatInfo } from '@store/reducers/chats';
import { toggleSigninModal } from '@store/reducers/modal';
import { RootState } from '@store/store';

import CommunityHeader from '@components/shared/community/headers/chat-header';
import InfoHeader from '@components/shared/community/headers/info-header';
import CommunitySideBar from '@components/shared/community/sidebar';
import NoDataPage from '@components/shared/no-data/NoDataPage';
import ChatHeaderSkeleton from '@skeletons/ChatHeader';
import InfoHeaderSkeleton from '@skeletons/Info';

import styles from './community.module.scss';
import Pages from '@pages';

function CommunityLayout() {
	const user = useSelector((state: RootState) => state.user.user);
	const chat = useSelector((state: any) => state.chats);
	const [search] = useSearchParams();
	const dispatch = useDispatch();

	const MainHeader = lazy(() => import('@components/shared/header'));
	const LeftSidebar = lazy(() => import('@shared/left-sidebar'));

	const setShowModal = () => dispatch(toggleSigninModal());

	const [getCommunity, getState] = useGetCommunityMutation();

	useEffect(() => {
		if (search.get('cid') !== null && search.get('cid') !== chat.activeChatId) {
			dispatch(setActiveChatId(search.get('cid') || ''));
			getCommunity({ id: search.get('cid') || '' })
				.unwrap()
				.then((data: any) => {
					if (data.success === 200) dispatch(setActiveChatInfo(data.result));
				})
				.catch((error: any) => dispatch(setActiveChatInfo({ success: error.status })));
		}
	}, [search.get('cid'), user]);

	const Header = () => (
		<>
			{chat.activePage !== 'info' ? (
				chat.activeChatInfo ? (
					<CommunityHeader />
				) : (
					<ChatHeaderSkeleton />
				)
			) : chat.activeChatInfo ? (
				<InfoHeader />
			) : (
				<InfoHeaderSkeleton />
			)}
		</>
	);

	return (
		<>
			<header className={`${styles.header} ${window.location.href.includes('community') ? styles.show : ''}`}>
				<MainHeader onClick={setShowModal} />
			</header>
			<main className={styles.main}>
				<aside className={styles.sidebar}>
					<LeftSidebar />
				</aside>
				<section className={styles.container}>
					<article className={styles.content}>
						{!getState?.isLoading || getState?.isSuccess ? (
							<>
								<Header />
								<Outlet />
							</>
						) : chat.activeChatInfo?.success === 403 ? (
							<NoDataPage data={'You are not a member of this community'} />
						) : (
							<Pages.CommunityNotFound />
						)}
					</article>
					<article className={styles.sidebar}>
						<CommunitySideBar />
					</article>
				</section>
			</main>
		</>
	);
}

export default CommunityLayout;
