import SkeletonAtom from './SkeletonAtom';
import styles from './styles.module.scss';

const ProfileHeaderSkeleton = () => {
	return (
		<div className={styles.follow}>
			<SkeletonAtom type='avatar-xxl' />
			<div className={styles.follow__header__info}>
				<SkeletonAtom type='username' />
				<SkeletonAtom type='name' />
			</div>
			<SkeletonAtom type='button' />
		</div>
	);
};

export default ProfileHeaderSkeleton;
