import { pollsBody } from '$types/store/endpoints/polls';
import api from '@store/api';
import { setParams } from 'src/utils/setParams';

const polls = api.injectEndpoints({
	endpoints: build => ({
		createPoll: build.mutation<{ success: number; result: string }, pollsBody>({
			query: body => ({
				url: '/v1/polls/',
				method: 'POST',
				body: body,
			}),
			invalidatesTags: ['post'],
		}),
		deletePoll: build.mutation<{ success: number; result: string }, { pollId: string }>({
			query: body => ({
				url: '/v1/polls/',
				method: 'DELETE',
				body: body,
			}),
		}),
	}),
	overrideExisting: false,
});

export const { useCreatePollMutation, useDeletePollMutation } = polls;
