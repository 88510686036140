import { Icon } from '@iconify/react';

import styles from './partials.module.scss';

interface NavIconProps {
	onClose: React.Dispatch<React.SetStateAction<boolean>>;
	submit: () => void;
}

const PostIcon = (props: NavIconProps) => {
	const { submit } = props;

	const handleSubmit = () => {
		submit();
	};

	return (
		<button className={styles.postIcon} onClick={handleSubmit}>
			<Icon icon={'fa6-regular:paper-plane'} color='var(--primary)' />
		</button>
	);
};

export default PostIcon;
