/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
//STORE
import { useValidateTaggedUsersMutation } from '@store/endpoints/community';
import { useGetMultipleImageUploadUrlMutation, useCreatePostMutation, useUpdatePostMutation } from '@store/endpoints/post';
import SocketContext from '@store/socket/Context';
import { RootState } from '@store/store';
//TYPES
import { CreatePostProps } from '$types/components/cards';
import { Audience } from '$types/components/post';
//COMPONENTS
import { handleUploadImages } from '@utils/mentions';
import { removeFromLocalStorage } from '@utils/localStorage';

import PollsInput from './sections/polls';
import PostInput from './sections/PostInput';
import { RENDERCONTENT } from '@data/postData';
import { PollDataProps } from '$types/components/pollData';
import { useCreatePollMutation } from '@store/endpoints/poll';

export type HandleSubmitProps = {
	post_text: string;
	images: string[];
	files: File[];
	audience: Audience;
};

const TAG_REGEX = /@\[[a-z0-9_]+\]\([a-f0-9]{24}\)/g;

const CreatePost = (props: CreatePostProps) => {
	const { setShowModal, updateId, initial } = props;

	const [post_text, setPostText] = useState<string>(initial?.post_text ?? '');
	const user = useSelector((state: RootState) => state.user.user);
	const [getImagesUrl] = useGetMultipleImageUploadUrlMutation();
	const { socket } = useContext(SocketContext).SocketState;
	const [validateTags] = useValidateTaggedUsersMutation();
	const [createPost] = useCreatePostMutation();
	const [createPoll] = useCreatePollMutation();
	const [updatePost] = useUpdatePostMutation();

	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [cardRender, setCardRender] = useState<string>(RENDERCONTENT.post);
	const [pollData, setPollData] = useState<PollDataProps>({
		userId: '',
		question: '',
		expiredAt: '',
		options: [],
	});

	const handleRenderSwitch = (value: string) => {
		setCardRender(value);
	};

	const getPollData = (data: PollDataProps) => {
		setPollData(data);
	};

	const removePollData = () => {
		setPollData({ userId: '', question: '', expiredAt: '', options: [] });
	};

	const checkIfPollExist = () => {
		return pollData.question !== '' && pollData.options.length > 0;
	};

	const handleSubmit = ({ post_text, images, files, audience }: HandleSubmitProps) => {
		if (!user?.isVerified) {
			setOpen(true);
			return;
		}
		if (!post_text && !images.length) {
			toast.error('Post must have some content!');
			return;
		}

		setLoading(true);

		let postText = post_text;

		const allTags = post_text.match(TAG_REGEX);
		allTags?.map(tag => {
			let split = '@' + tag.split('(')[1].split(')')[0];
			postText = postText.replaceAll(tag, split);
		});

		const taggedUsers = allTags?.map(item => item.split('(')[1].split(')')[0]) ?? [];

		validateTags({ cid: '', taggedUsers: taggedUsers })
			.unwrap()
			.then(async (res: any) => {
				if (res.success === 200) {
					const validTags = res.result.map((_tags: { _id: string; username: string }) => _tags._id);
					// upload the image
					let imagesLinks = [];
					if (files.length) imagesLinks = await handleUploadImages({ files, getUrls: getImagesUrl });
					const postBody = {
						post_text: postText,
						share_with: audience,
						userId: user?._id ?? '',
						taggedUser: validTags ?? [],
						image_links: updateId ? images : imagesLinks ?? [],
					};
					if (updateId) {
						const loadingToastId = toast.loading('Updating post...');
						updatePost({
							postId: updateId,
							body: postBody,
						})
							.unwrap()
							.then((data: any) => {
								toast.update(loadingToastId, {
									render: 'Post updated successfully! 👌',
									type: toast.TYPE.SUCCESS,
									isLoading: false,
									autoClose: 3000,
								});
								removeFromLocalStorage('post');
							})
							.catch(error => {
								toast.update(loadingToastId, {
									render: error?.data?.error || 'Something went wrong',
									type: toast.TYPE.ERROR,
									isLoading: false,
									autoClose: 3000,
								});
							});
						return;
					} else {
						const loadingToastId = toast.loading('Creating post...');
						createPost(postBody)
							.unwrap()
							.then(data => {
								// create the poll
								if (checkIfPollExist()) {
									createPoll({ ...pollData, options: pollData.options.map(opt => opt.option), postId: data._id })
										.unwrap()
										.then(pollRes => {
											toast.update(loadingToastId, {
												render: 'Poll created successfully! 👌',
												type: toast.TYPE.SUCCESS,
												isLoading: false,
												autoClose: 3000,
											});
											/* Update Toast loading event. */
											socket?.emit('poll:created', {
												userId: user?._id,
												entityId: data._id,
												taggedUser: validTags,
												pollText: pollData.question,
											});
											if (validTags.length > 0) {
												socket?.emit('posts:mentioned', {
													userId: user?._id,
													entityId: data._id,
													taggedUser: validTags,
												});
											}
										})
										.catch(error => {
											toast.update(loadingToastId, {
												render: error?.data?.error || 'Something went wrong',
												type: toast.TYPE.ERROR,
												isLoading: false,
												autoClose: 3000,
											});
										});
								} else {
									toast.update(loadingToastId, {
										render: 'Post created successfully! 👌',
										type: toast.TYPE.SUCCESS,
										isLoading: false,
										autoClose: 3000,
									});
									removeFromLocalStorage('post');
									// for regular post notification
									socket?.emit('posts:created', {
										userId: user?._id,
										entityId: data._id,
										taggedUser: validTags,
										postText: postBody.post_text ?? '',
										postImage: postBody.image_links && postBody.image_links.length ? postBody.image_links[0] : '',
									});
									// for sending notifications to users
									if (validTags.length > 0) {
										socket?.emit('posts:mentioned', {
											userId: user?._id,
											entityId: data._id,
											taggedUser: validTags,
										});
									}
								}
							})
							.catch(error => {
								toast.update(loadingToastId, {
									render: error?.data?.error || 'Something went wrong',
									type: toast.TYPE.ERROR,
									isLoading: false,
									autoClose: 3000,
								});
							});
					}
					setShowModal(false);
				} else {
					toast.error('Something went wrong!');
				}
				setLoading(false);
			});
	};

	const renderCardContent = (value: string) => {
		switch (value) {
			case RENDERCONTENT.post:
				return (
					<PostInput
						loading={loading}
						open={open}
						setOpen={setOpen}
						handleSubmit={handleSubmit}
						setShowModal={setShowModal}
						switchContent={handleRenderSwitch}
						pollsData={pollData}
						removePollData={removePollData}
						initial={initial}
						setPostText={setPostText}
						post_text={post_text}
						updateId={updateId}
						setPollData={getPollData}
					/>
				);
			case RENDERCONTENT.polls:
				return <PollsInput switchContent={handleRenderSwitch} submit={getPollData} pollData={pollData} />;
		}
	};

	return <>{renderCardContent(cardRender)}</>;
};

export default CreatePost;
