import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
//TYPES
import { CreatePostFooterProps } from '$types/components/cards';
import { RootState } from '$types/store/store';
//STYLES
import styles from './partials.module.scss';
//COMPONENTS
// import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
// import HelperTooltip from '@components/partials/helperTooltip';
import EmojiPicker from '@components/dropdowns/EmojiDropdown';
import { RENDERCONTENT } from '@data/postData';
import { toast } from 'react-toastify';

function Footer(props: CreatePostFooterProps) {
	const { updateId, setOpen, setPostText, handleImage, postText, switchContent, canUploadImage, imagesCount } = props;

	const user = useSelector((state: RootState) => state.user.user);

	// const [showPollsTooltip, setShowPollsTooltip] = useState(false);

	const EmojiHandler = (emoji: any) => {
		setPostText((prev: string) => prev + emoji.native);
	};

	// uncomment this when launching the polls feature fully
	// useEffect(() => {
	// 	const res = getFromLocalStorage('IntroducePollsFeature');
	// 	if (res && res.show) {
	// 		setShowPollsTooltip(true);
	// 	} else {
	// 		setShowPollsTooltip(false);
	// 	}

	// 	setTimeout(() => {
	// 		setShowPollsTooltip(false);
	// 		addToLocalStorage('IntroducePollsFeature', { show: false });
	// 	}, 5000);
	// }, []);

	return (
		<footer className={styles.actions}>
			<div className={styles.btns}>
				{!updateId && canUploadImage ? (
					<label htmlFor='post-file-input' className={styles.icon}>
						<Icon
							icon='material-symbols:broken-image-outline'
							color='var(--primary)'
							className={styles.icon}
							onClick={() => !user?.isVerified && setOpen(true)}
						/>
					</label>
				) : null}
				{user?.isVerified && canUploadImage ? (
					<input type='file' multiple className={styles.file} id='post-file-input' accept='image/*' onChange={handleImage} />
				) : null}
				<EmojiPicker EmojiHandler={EmojiHandler} placement={4} />
				{!updateId ? (
					// !TASK => uncomment this when launching the polls feature fully
					// <HelperTooltip message='Create polls by clicking here!' display={showPollsTooltip} position='top'>
					<button
						onClick={() => {
							if (imagesCount > 0) {
								toast.error('You cannot have a poll and images in the same post!');
							} else {
								switchContent(RENDERCONTENT.polls);
							}
						}}>
						<Icon icon='mdi:poll' color='var(--primary)' />
					</button>
				) : // </HelperTooltip>
				null}
			</div>
			<div className={styles.wordCounter}>{postText.split(' ').length}/400</div>
		</footer>
	);
}

export default Footer;
