import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
//TYPES
import { PollDataProps } from '$types/components/pollData';
//STYLES
import styles from './polls.module.scss';
//COMPONENTS
import { getDaysRemainingUntil } from '@utils/formatDate';
import { RENDERCONTENT } from '@data/postData';
import { RootState } from '@store/store';

const POLL_DURATIONS = [
	{ value: 1, label: '1 day' },
	{ value: 3, label: '3 days' },
	{ value: 7, label: '1 week' },
	{ value: 14, label: '2 weeks' },
];

type PollProps = {
	switchContent: (val: string) => void;
	submit: (data: PollDataProps) => void;
	pollData: PollDataProps;
};

const QUESTION_WORD_LIMIT = 20;
const OPTION_WORD_LIMIT = 10;

function PollsInput(props: PollProps) {
	const { switchContent, submit, pollData } = props;
	const user = useSelector((state: RootState) => state.user.user);

	const [selectedOption, setSelectedOption] = useState<{ value: number; label: string }>(POLL_DURATIONS[0]);
	const [expiredAt, setExpiredAt] = useState(POLL_DURATIONS[0].value);
	const [options, setOptions] = useState([{ option: '' }, { option: '' }]);
	const [question, setQuestion] = useState('');

	const handleAddOption = () => {
		if (options.length < 4) {
			setOptions(prev => [...prev, { option: '' }]);
		} else {
			toast.error('You can add maximum 4 options!');
		}
	};

	const handleRemoveOption = (i: number) => {
		if (i > 2) {
			setOptions(prev => prev.filter((opt, idx) => idx + 1 !== i));
		} else {
			toast.error('You need to have minimum 2 options!');
		}
	};

	const validatePoll = () => {
		if (question === '') {
			toast.error(`Please enter a valid question!`);
			return false;
		}
		if (question.split(' ').length > QUESTION_WORD_LIMIT) {
			toast.error(`Question must contain less than ${QUESTION_WORD_LIMIT} words`);
			return false;
		}
		if (options.length > 4 || options.length < 2) {
			toast.error(`Options should be between 2 and 4`);
			return false;
		}

		if (options.find(opt => opt.option === '')) {
			toast.error(`Options cannot be empty`);
			return false;
		}
		if (options.find(opt => opt.option.split(' ').length > OPTION_WORD_LIMIT)) {
			toast.error(`Options must contain less than ${OPTION_WORD_LIMIT} words`);
			return false;
		}
		if (!expiredAt) {
			toast.error(`Please add an expiry date`);
			return false;
		}

		return true;
	};

	const calculateExpiry = (expiry: number, ISODate: string) => {
		const date = new Date(ISODate);
		switch (expiry) {
			case 7:
				date.setDate(date.getDate() + 7);
				return date.toISOString();
			case 1:
				date.setDate(date.getDate() + 1);
				return date.toISOString();
			case 3:
				date.setDate(date.getDate() + 3);
				return date.toISOString();
			case 14:
				date.setDate(date.getDate() + 14);
				return date.toISOString();
			default:
				date.setDate(date.getDate() + 7);
				return date.toISOString();
		}
	};

	const handleSubmit = () => {
		const isValidPoll = validatePoll();

		if (!isValidPoll) return;
		const expiryDate = calculateExpiry(expiredAt, new Date().toISOString());
		if (!user?._id) {
			toast.error(`Unauthorized!`);
			return;
		}
		if (options.length < 2 || options.length > 5) return;
		const pollData: PollDataProps = {
			userId: user?._id,
			question: question,
			expiredAt: expiryDate,
			options: options.map(opt => ({ ...opt, opt, _id: '' })),
		};

		submit(pollData);
		switchContent(RENDERCONTENT.post);
	};

	useEffect(() => {
		if (pollData.question !== '' && pollData.options.length !== 0) {
			const daysUntil = getDaysRemainingUntil(pollData.expiredAt);

			setQuestion(pollData.question);
			setOptions(pollData.options.map(opt => ({ option: opt.option })));
			setExpiredAt(daysUntil);
			const va = POLL_DURATIONS.findIndex(dur => dur.value === daysUntil);
			setSelectedOption(POLL_DURATIONS[va]);
		}
	}, []);

	return (
		<article className={styles.polls}>
			<label htmlFor='question'>Your question</label>
			<textarea
				className={styles.quesionInput}
				rows={2}
				name='question'
				value={question}
				onChange={e => {
					if (e.target.value.split(' ').length <= QUESTION_WORD_LIMIT && e.target.value.length <= 400) {
						setQuestion(e.target.value);
					}
				}}
			/>
			<p className={styles.inputWordCount}>
				{question.split(' ').length} / {QUESTION_WORD_LIMIT}
			</p>
			<div className={styles.options}>
				{options.map((option, i) => (
					<div className={styles.option} key={i}>
						<label htmlFor='question'>
							Option {i + 1}
							{i + 1 > 2 && (
								<span className={styles.remove} onClick={() => handleRemoveOption(i + 1)}>
									remove
								</span>
							)}
						</label>
						<input
							type='text'
							value={option.option}
							onChange={e => {
								if (e.target.value.split(' ').length <= OPTION_WORD_LIMIT && e.target.value.length <= 300) {
									const updatedOptions = options;
									updatedOptions[i].option = e.target.value;
									setOptions([...updatedOptions]);
								}
							}}
						/>
						<p className={styles.inputWordCount}>
							{option.option.split(' ').length} / {OPTION_WORD_LIMIT}
						</p>
					</div>
				))}
			</div>
			{options.length < 4 ? (
				<button className={styles.addBtn} onClick={handleAddOption}>
					+ Add option
				</button>
			) : null}
			<div className={styles.duration}>
				<p>Poll duration</p>
				<Select
					options={POLL_DURATIONS}
					classNames={{
						container: () => styles['input-field-select'],
						control: ({ isFocused }) => (isFocused ? styles['control-color'] : ''),
						option: ({ isSelected, isFocused }) => {
							return isSelected ? styles['color-selected'] : isFocused ? styles['color-selected'] : '';
						},
					}}
					value={selectedOption}
					onChange={e => {
						if (e) {
							setExpiredAt(e.value);
							setSelectedOption(e);
						}
					}}
				/>
			</div>
			<div className={styles.action}>
				<button className={styles.back} onClick={() => switchContent(RENDERCONTENT.post)}>
					Back
				</button>
				<button className={styles.create} onClick={handleSubmit}>
					Create
				</button>
			</div>
		</article>
	);
}

export default PollsInput;
