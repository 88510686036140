import React from 'react';

import nodata from '@images/nodata.svg';
import noresult from '@images/noresult.svg';
import styles from './noDataPage.module.scss';
import LazyImage from '../lazy-image';

function NoDataPage({ data }: { data: string | undefined }) {
	return (
		<div className={styles.ndpCont}>
			<LazyImage src={data?.includes('search') || data?.includes('likes') ? noresult : nodata} alt='no media' />
			<h1 className={styles.ndpText}>{data || 'No Data'}</h1>
		</div>
	);
}

export default NoDataPage;
