import { configureStore } from '@reduxjs/toolkit';

import reactionsReducer from './reducers/reactions';
import profileReducer from './reducers/profile';
import contextReducer from './reducers/context';
import modalReducer from "./reducers/modal";
import chatsReducer from './reducers/chats';
import userReducer from './reducers/user';
import theme from './reducers/theme';
import api from './api';

export const store = configureStore({
	reducer: {
		// Static Reducers
		theme,
		user: userReducer,
		chats: chatsReducer,
		modal : modalReducer,
		context: contextReducer,
		profile: profileReducer,
		reactions: reactionsReducer,

		// API Reducers
		[api.reducerPath]: api.reducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware).concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
