import { Community, CommunityPagination, CommunityParams, communityProps, memberProps } from '$types/components/community';
import { setParams } from 'src/utils/setParams';
import api from '@store/api';
import { AdminsCommunityResponse, SuggestedMemberResponse, ValidTaggedUserResponse } from '$types/store/endpoints/community';

const community = api.injectEndpoints({
	endpoints: builder => ({
		getJoinedCommunities: builder.query<CommunityPagination, { id: string; page: number; limit: number }>({
			query: params => ({
				url: `/v1/members/joined-community?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},

			providesTags: (result, error, params) => [
				{ type: 'joined-community', id: params?.id, page: params.page, limit: params.limit },
			],
		}),
		getPinnedCommunities: builder.query<communityProps[], null>({
			query: params => ({
				url: `/v1/members/pinned-community`,
				method: 'GET',
			}),
		}),
		getDefaultCommunity: builder.query<any, null>({
			query: params => ({
				url: `/v1/communities/default`,
				method: 'GET',
			}),
			providesTags: (result, error) => [{ type: 'community' }],
		}),
		getMembers: builder.query<memberProps, { cid: string; page: number; limit: number }>({
			query: params => ({
				url: `/v1/members/get-members?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: (result, error, params) => [
				{ type: 'get-members', cid: params.cid, page: params.page, limit: params.limit },
			],
		}),
		getMemberSuggestions: builder.mutation<SuggestedMemberResponse, { cid: string; key: string }>({
			query: params => ({
				url: `/v1/members/suggest-members?${setParams(params)}`,
				method: 'GET',
			}),
		}),
		validateTaggedUsers: builder.mutation<ValidTaggedUserResponse, { cid?: string; taggedUsers: string[] }>({
			query: params => ({
				url: '/v1/members/validate-tags',
				method: 'POST',
				body: {
					cid: params.cid ?? null,
					taggedUsers: params.taggedUsers,
				},
			}),
		}),
		getCommunity: builder.mutation<{ success: number; result: communityProps }, { id: string }>({
			query: params => ({
				url: `/v1/communities/community?cid=${params.id}`,
				method: 'GET',
			}),
			invalidatesTags: (result, error, params) => [{ type: 'community', id: params.id }],
		}),
		getPublicCommunities: builder.query<CommunityPagination, { page: number; limit: number }>({
			query: params => ({
				url: `/v1/communities/unrestriced-community?page=${params.page}&limit=${params.limit}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({ endpointName }) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (currentCache.next && currentCache.next.page > 1) {
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				} else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},

			providesTags: (result, error, params) => [{ type: 'community', page: params.page, limit: params.limit }],
		}),
		joinCommunity: builder.mutation<{ success: number; result: communityProps }, { communityId: string }>({
			query: params => ({
				url: `/v1/members/join-community`,
				method: 'POST',
				body: {
					communityId: params.communityId,
				},
			}),

			invalidatesTags: (result, error, params) => [{ type: 'joined-community', kind: 'public' }],
		}),
		leaveCommunity: builder.mutation<{ success: number; result: communityProps }, { communityId: string }>({
			query: params => ({
				url: `/v1/members/leave-community?cid=${params.communityId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, params) => [{ type: 'leave-community', kind: 'public' }],
		}),
		pinCommunity: builder.mutation<{ success: number; result: communityProps }, { communityId: string }>({
			query: params => ({
				url: `/v1/members/pin-community?cid=${params.communityId}`,
				method: 'PATCH',
			}),
			invalidatesTags: (result, error, params) => [{ type: 'joined-community', kind: 'public' }],
		}),
		communityType: builder.query<{ success: number; result: string }, { cid: string }>({
			query: params => ({
				url: `/v1/communities/community-type?${setParams(params)}`,
				method: 'GET',
			}),
			providesTags: (result, error) => [{ type: 'community-type' }],
		}),
		searchCommunity: builder.mutation<CommunityPagination, { q: string; page?: number; limit?: number }>({
			query: params => ({
				url: `/v1/communities/search?${setParams(params)}`,
				method: 'GET',
			}),
			invalidatesTags: (result, error, params) => [{ type: 'search-community', page: params.page, limit: params.limit }],
		}),
		searchJoinedOrPublicCommunity: builder.mutation<CommunityPagination, { q: string; page?: number; limit?: number }>({
			query: params => ({
				url: `/v1/communities/searchcommunity?${setParams(params)}`,
				method: 'GET',
			}),
			invalidatesTags: (result, error, params) => [{ type: 'search-community', page: params.page, limit: params.limit }],
		}),
		allCommunityByUser: builder.mutation<CommunityPagination, { q: string; uid: string; page: number; limit: number }>({
			query: params => ({
				url: `/v1/communities/community-by-user?${setParams(params)}`,
				method: 'GET',
			}),
			invalidatesTags: (result, error, params) => [{ type: 'all-community-by-user' }],
		}),
		getCommunityByAdmin: builder.query<AdminsCommunityResponse, null>({
			query: params => ({
				url: `/v1/communities/get-admins-communities`,
				method: 'GET',
			}),
		}),
		createCommunity: builder.mutation<{ success: number; result: Community }, CommunityParams>({
			query: params => {
				return {
					url: '/v1/communities/community',
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: params,
				};
			},
			invalidatesTags: ['post'],
		}),
		updateCommunity: builder.mutation<{ success: number; result: string }, { communityId: string; body: CommunityParams }>({
			query: ({ communityId, body }) => ({
				url: `/v1/communities/community?cid=${communityId}`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: (result, error, { communityId }) => [{ type: 'community', id: communityId }],
		}),
		deleteCommunity: builder.mutation<{ success: number; result: string }, { communityId: string }>({
			query: ({ communityId }) => ({
				url: `/v1/communities/community?cid=${communityId}`,
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'community' }],
		}),
	}),
	overrideExisting: false,
});

export const {
	useSearchJoinedOrPublicCommunityMutation,
	useGetJoinedCommunitiesQuery,
	useGetPinnedCommunitiesQuery,
	useGetDefaultCommunityQuery,
	useGetCommunityMutation,
	useGetPublicCommunitiesQuery,
	useJoinCommunityMutation,
	useGetMembersQuery,
	useLeaveCommunityMutation,
	usePinCommunityMutation,
	useCommunityTypeQuery,
	useSearchCommunityMutation,
	useAllCommunityByUserMutation,
	useGetCommunityByAdminQuery,
	useCreateCommunityMutation,
	useUpdateCommunityMutation,
	useDeleteCommunityMutation,
	useGetMemberSuggestionsMutation,
	useValidateTaggedUsersMutation,
} = community;
