import { networkPagination, networkResponse } from '$types/store/endpoints/network';
import api from '@store/api';
import { setParams } from 'src/utils/setParams';

const network = api.injectEndpoints({
	endpoints: builder => ({
		getFollowings: builder.query<networkPagination, { user: string, page?: number, limit?: number }>({
			query: params => ({
				url: `/v1/network/get-network?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({endpointName}) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if(currentCache.next && currentCache.next.page>1){
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				}else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			  },
			providesTags: ['following'],
		}),
		getFollowers: builder.query<networkPagination, { user: string, page?: number, limit?: number }>({
			query: params => ({
				url: `/v1/network/get-followers?${setParams(params)}`,
				method: 'GET',
			}),
			serializeQueryArgs: ({endpointName}) => endpointName,
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if(currentCache.next && currentCache.next.page>1){
					currentCache.next = newItems.next;
					currentCache.prev = newItems.prev;
					currentCache.total = newItems.total;
					currentCache.data.push(...newItems.data);
					return currentCache;
				}else return newItems;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			  },
			providesTags: ['follower'],
		}),
		follow: builder.mutation<networkResponse, { networkId: string }>({
			query: params => ({
				url: '/v1/network/follow',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['following', 'user', 'usersignin'],
		}),
		unfollow: builder.mutation<networkResponse, { networkId: string }>({
			query: params => ({
				url: '/v1/network/unfollow?networkId=' + params.networkId,
				method: 'DELETE',
			}),
			invalidatesTags: ['following', 'user', 'usersignin'],
		}),
	}),
});

export const { useGetFollowingsQuery, useGetFollowersQuery, useFollowMutation, useUnfollowMutation} = network;
