import SkeletonAtom from './SkeletonAtom';
import styles from './styles.module.scss';

const ListItemSkeleton = ({ count }: { count?: number }) => {
	return (
		<>
			{new Array(count || 1).fill(null).map((item, i) => (
				<div key={i} className={styles.follow}>
					<SkeletonAtom type='avatar' />
					<div className={styles.follow__header__info}>
						<SkeletonAtom type='username' />
						<SkeletonAtom type='name' />
					</div>
					<SkeletonAtom type='button' />
				</div>
			))}
		</>
	);
};

export default ListItemSkeleton;
