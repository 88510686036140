import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { MouseEvent, useEffect } from 'react';
import { Icon } from '@iconify/react';

import { setActiveSection } from '@store/reducers/profile';

import { STAGES } from '$types/pages/profile';

import styles from './style.module.scss';

const ProfileStage = () => {
	const profile = useSelector((state: any) => state.profile);
	const { type, username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleStageClick = (e: MouseEvent<HTMLAnchorElement>, key: string) => {
		e.preventDefault();
		dispatch(setActiveSection(key));
		if (key === 'profile') navigate(`/profile/${username}`);
		else navigate(`/profile/${username}/activity/${key}`);
	};

	const getIcons = (stageName: string) => {
		switch (stageName) {
			case 'Profile':
				return 'iconamoon:profile-fill';
			case 'Posts':
				return 'solar:posts-carousel-horizontal-bold';
			case 'Interactions':
				return 'mdi:interaction-tap';
			default:
				return 'iconamoon:profile-fill';
		}
	};

	useEffect(() => {
		dispatch(setActiveSection(type));
	}, []);

	return (
		<div className={styles.stage__select}>
			{Object.keys(STAGES).map(stage => {
				const stageName = STAGES[stage as keyof typeof STAGES];
				return (
					<a
						className={`${styles.item} ${stage === profile.activeSection ? styles.active : ''}`}
						key={stage}
						onClick={e => handleStageClick(e, stage)}>
						<Icon icon={getIcons(stageName)} className={styles.icon} />
						{stageName}
					</a>
				);
			})}
		</div>
	);
};

export default ProfileStage;
